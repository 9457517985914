import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const FooterSpi = () =>{
    return (
        <>
        <link rel="stylesheet" href="./customSPIP.css" />
        <div style={{color:'black',backgroundColor:'hsla(37, 90%, 51%, 1)'}}>
            <div className="row ">
                <div className="col">
                    <div className="container-md text-center">
                            <p className="muted">Hak Cipta © 2023 Komisi Pemberantasan Korupsi. Semua Hak Dilindungi.</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    )

}

export default FooterSpi