import React from 'react';
import KuesionerPages from "./pages/kuesionerpages/KuesionerPages";

const Home = React.lazy(() => import('./pages/home/Home'));
const Dashboard = React.lazy(() => import('./pages/dashboard/index'));
const Settings = React.lazy(() => import('./pages/settings/index'));
const MasterData = React.lazy(() => import('./pages/masterdata/index'));
const IsiSurvey = React.lazy(() => import('./pages/isisurvey/index'));
const DataView = React.lazy(() => import('./pages/dataview/index'));
const GantiPassword = React.lazy(() => import('./pages/gantipassword/GantiPassword'));


const routes = [
    // ...KuesionerPages,
    {path: '/home', name: 'Home', component: Home},
    {path: '/dashboard', name: 'Dashboard', component: Dashboard},
    {path: '/settings', name: 'Settings', component: Settings},
    {path: '/masterdata', name: 'MasterData', component: MasterData},
    {path: '/isisurvey', name: 'Survey', component: IsiSurvey},
    {path: '/dataview', name: 'DataView', component: DataView},
    {path: '/gantipassword', name: 'GantiPassword', component: GantiPassword},
    {path: '', name: 'Home', component: Home}
];

export const routesPages = [
    ...KuesionerPages
];

export default routes;
