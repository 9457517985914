import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


const FaqContent = () =>{
    return (
        <>
        <div className="col-12 col-md-9">
                        <div className="tab-content pt-3" id="pills-tabContent">
                            <div className="tab-pane fade active show" id="pills-background" role="tabpanel" aria-labelledby="pills-background-tab">
                                <div className="container">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <strong><h5>Apa itu Survei Penilaian Integritas Pendidikan?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Survei Penilaian Integritas Pendidikan atau disebut juga SPI Pendidikan adalah survei yang dilakukan sebagai upaya untuk memetakan kondisi integritas pendidikan, baik pada lingkup peserta didik maupun ekosistem pendidikan yang memengaruhinya seperti tenaga pendidik, pimpinan, termasuk aspek pengelolaan. Hasil pemetaan melalui SPI Pendidikan diharapkan dapat dijadikan dasar dan pertimbangan dalam menyusun rekomendasi peningkatan dan pengembangan upaya implementasi pendidikan karakter dan budaya antikorupsi yang lebih tepat sasaran.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <strong><h5>Apa manfaat/tujuan survei ini bagi saya?</h5></strong>
                                                    </button>
                                                </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Membantu pemetaan kebutuhan program pendidikan antikorupsi melalui integrasi penanaman nilai-nilai integritas dengan kurikulum pada satuan pendidikan dan mendorong terciptanya ekosistem pendidikan yang antikorupsi. Dalam jangka panjang, diharapkan dapat berkontribusi menciptakan generasi yang berintegritas dan antikorupsi, termasuk mengurangi korupsi yang terjadi pada sektor pendidikan di Indonesia. Kontribusi Anda sangat diharapkan demi tercapainya tujuan tersebut.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <strong><h5>Berapa lama survei akan berlangsung?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Survei ini akan berlangsung selama 2 (dua) bulan, mulai dari bulan <strong>Agustus</strong> sampai dengan <strong>September</strong> 2023.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    <strong><h5>Siapa saja satuan pendidikan yang terpilih/terlibat untuk mengikuti SPI Pendidikan tahun 2023 ini?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Survei ini melibatkan 3.537 satuan pendidikan yang terdiri dari 1.636 Sekolah Dasar; 999 Sekolah Menengah Pertama (SMP)/sederajat; 657 Sekolah Menengah Atas (SMA)/sederajat; dan 245 Perguruan Tinggi.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                    <strong><h5>Siapa yang dapat berpartisipasi di survei ini?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Terdapat 4 (empat) kategori responden yang dapat mengisi survei ini, yaitu siswa/mahasiswa, orang tua siswa, tenaga pendidik/pengajar, dan pimpinan satuan pendidikan dengan kriteria masing-masing kategori sebagai berikut.<br/><br/>

                                                <strong>Kriteria Siswa:</strong><br/>
                                                Merupakan siswa kelas 5, 6, 8, 9, 11, dan 12 dari setiap sekolah terpilih.
                                                <br/><br/>


                                                <strong>Kriteria Orang Tua Siswa:</strong><br/>
                                                Merupakan orang tua dari siswa kelas 5, 6, 8, 9, 11, dan 12 yang terpilih sebagai responden dari sekolah terpilih.
                                                <br/><br/>

                                                <strong>Kriteria Mahasiswa:</strong><br/>
                                                Merupakan mahasiswa yang berada pada tingkat II atau semester 3 ke atas dari setiap perguruan tinggi terpilih.
                                                <br/><br/>

                                                <strong>Kriteria Tenaga Pendidik/Pengajar:</strong><br/>
                                                Seluruh guru, baik ASN maupun Non-ASN (honorer), yang aktif mengajar mulai dari kelas 1 hingga kelas 12; baik wali kelas, maupun guru mata pelajaran/guru ekstra kurikuler/guru BP. 
                                                <br/>Seluruh dosen, baik ASN maupun Non-ASN (honorer), yang aktif mengajar mulai jenjang D1 hingga S3.
                                                <br/><br/>

                                                <strong>Kriteria Pimpinan Satuan Pendidikan:</strong><br/>
                                                Kepala Sekolah dan/atau Wakil Kepala Sekolah.
                                                <br/>Rektor dan/atau Wakil Rektor atau Dekan.

                                                <br/><br/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    <strong><h5>Bagaimana metode pelaksanaan survei?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Pelaksanaan survei dilakukan dengan cara memilih terlebih dahulu responden dari masing-masing satuan pendidikan terpilih. Pemilihan responden akan dilakukan secara acak. Selanjutnya, responden terpilih akan menerima tautan kuesioner melalui WhatsApp resmi bercentang hijau dengan nama pengirim <strong>Indekstat Research.</strong>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    <strong><h5>Bagaimana apabila siswa yang menjadi responden tidak memiliki <em>handphone/smartphone?</em></h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Siswa yang menjadi responden yang tidak memiliki <em>handphone/smartphone</em> dapat mengisi kuesioner melalui <em>handphone/smartphone</em> orang tua/wali. Tautan kuesioner akan diberikan kepada orang tua/wali siswa.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                    <strong><h5>Bagaimana metode pelaksanaan survei jika siswa ataupun orang tua/wali tidak memiliki akses internet karena berdomisili di pelosok?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Untuk siswa maupun orang tua/wali yang tidak memiliki akses internet karena berada di pelosok, maka KPK akan menyelenggarakan CAPI (<em>Computer-Assisted Personal Interview</em>). KPK akan mengirimkan petugas/enumerator yang akan membantu siswa terpilih dalam pengisian survei dengan meminjamkan <em>handphone/smartphone</em> yang memiliki aplikasi untuk pengisian survei ini.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                    <strong><h5>Bagaimana siswa mengisi survei jika pihak sekolah tidak memperbolehkan siswa memiliki <em>handphone/smartphone?</em></h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                <strong>Alternatif 1:</strong><br/>
                                                Survei dilakukan dengan metode CAWI (<em>Computer-Assisted Web Interviewing</em>). Pihak sekolah akan memfasilitasi siswa menggunakan laptop atau komputer dari sekolah pada saat pengisian survei. KPK akan mengirimkan tautan kuesioner melalui wali kelas untuk selanjutnya diteruskan kepada siswa yang terpilih sebagai responden.
                                                <br/><br/>
                                                <strong>Alternatif 2:</strong><br/>
                                                KPK akan menyelenggarakan CAPI (<em>Computer-Assisted Personal Interview</em>). KPK akan mengirimkan petugas/enumerator yang akan membantu siswa yang terpilih sebagai responden dalam pengisian survei dengan meminjamkan <em>handphone/smartphone</em> yang memiliki aplikasi untuk pengisian survei ini.

                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                    <strong><h5>Siapa yang bertanggung jawab atas survei ini?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Komisi Pemberantasan Korupsi (KPK) bertanggung jawab atas pelaksanaan SPI Pendidikan ini. Dalam pelaksanaannya, KPK dibantu oleh Indekstat.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
                                                    <strong><h5>Siapa Indekstat?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseFifteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Indekstat merupakan perusahaan riset dan konsultan independen yang berbasis di Indonesia. Indekstat secara resmi ditunjuk oleh KPK untuk melaksanakan SPI Pendidikan tahun 2023 melalui mekanisme lelang terbuka sebagaimana tercantum pada <a href="https://drive.google.com/drive/folders/1_bsrnFp09bw7EcjgaKodJ9F4MUiFD4GP?usp=sharing">surat berikut</a>.
                                                    <br/><br/>
                                                    Alamat:<br/>
                                                    SCBD, Marquee Equity Tower Floor 37th, Jl. Jend. sudirman kav 52-53, Senayan, Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12190.<br/>
                                                    <br/>
                                                    Kontak :<br/>
                                                    WhatsApp : 0896-2089-5853<br/>
                                                    E-Mail : admin@indekstat.com<br/>
                                                    Instagram : @indekstat<br/>
                                                    https://indekstat.com<br/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen2" aria-expanded="false" aria-controls="collapseFifteen2">
                                                    <strong><h5>Apabila ada hal yang ingin ditanyakan, siapa yang dapat kami hubungi?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseFifteen2" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Anda dapat menghubungi PIC (<em>person in charge</em>) dari kegiatan ini, yaitu <strong>Sdr. Zulfadhli Nasution</strong> (Zulfadhli.Nasution@kpk.go.id) atau <strong>Sdri. Pipin Purbowati</strong> (Pipin.Purbowati@kpk.go.id).
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="pills-privacy_policy" role="tabpanel" aria-labelledby="pills-privacy_policy-tab">
                                <div className="container">
                                    <div className="accordion" id="accordionExample">

                                        <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
                                                        <strong><h5>Apa dasar hukum pelaksanaan SPI Pendidikan ini?</h5></strong>
                                                    </button>
                                                </h2>
                                                <div id="collapseSixteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        Undang-Undang Nomor 19 Tahun 2019 <em>Juncto</em> Undang-Undang Nomor 30 Tahun 2002 tentang Komisi Pemberantasan Tindak Pidana Korupsi pasal 7 menyebutkan bahwa dalam melaksanakan tugas pencegahan sebagaimana dimaksud dalam pasal 6 huruf a, KPK berwenang:<br/><br/>
                                                        - menyelenggarakan program pendidikan antikorupsi pada setiap jejaring pendidikan;<br/>
                                                        - merencanakan dan melaksanakan program sosialisasi Pemberantasan Tindak Pidana Korupsi;<br/>
                                                        - melakukan kampanye antikorupsi kepada masyarakat.<br/><br/>

                                                        Undang-Undang Nomor 20 Tahun 2003 tentang Sistem Pendidikan Nasional pasal 3 menyebutkan bahwa pendidikan nasional berfungsi mengembangkan kemampuan dan membentuk watak serta peradaban bangsa yang bermartabat dalam rangka mencerdaskan kehidupan bangsa, bertujuan untuk berkembangnya potensi peserta didik agar menjadi manusia yang beriman dan bertakwa kepada Tuhan Yang Maha Esa, berakhlak mulia, sehat, berilmu, cakap, kreatif, mandiri, dan menjadi warga negara yang demokratis serta bertanggung jawab.
                                                    </div>
                                                </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseFourteen" aria-expanded="false" aria-controls="collapseFourteen">
                                                    <strong><h5>Bagaimana saya dapat terpilih sebagai responden?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseFourteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Pemilihan responden dilakukan dengan metode <em>probability random sampling</em>, dimana setiap anggota populasi memiliki peluang yang sama untuk menjadi responden.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                        <strong><h5>Apa yang saya dapat jika saya berpartisipasi di survei ini?</h5></strong>
                                                    </button>
                                                </h2>
                                            <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Penyelenggaraan survei ini sepenuhnya adalah untuk kepentingan non-profit tanpa melibatkan skema pembayaran apapun. Adapun manfaat atau tujuan survei ini adalah untuk membantu upaya pencegahan korupsi melalui pemetaan dan monitoring risiko korupsi yang terjadi pada sektor pendidikan. Kontribusi Anda sangat diharapkan demi tercapainya tujuan tersebut.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                        <strong><h5>Apakah ada sanksi jika saya tidak berpartisipasi di survei ini?</h5></strong>
                                                    </button>
                                                </h2>
                                            <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Survei ini bersifat sukarela dan tidak ada konsekuensi apapun baik terhadap individu maupun operasional satuan pendidikan jika tidak berpartisipasi. Namun dengan mengisi survei ini, Anda telah ikut berperan aktif dalam membantu menciptakan generasi yang lebih berintegritas dan membantu pencegahan korupsi di Indonesia.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
                                                    <strong><h5>Apakah survei ini legal/aman/valid?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseThirteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                SPI Pendidikan merupakan survei yang secara resmi diselenggarakan oleh KPK. <em>Press release</em> resmi SPI Pendidikan dapat diakses melalui <a href="https://drive.google.com/drive/folders/1_bsrnFp09bw7EcjgaKodJ9F4MUiFD4GP?usp=sharing">tautan ini.</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
                                                    <strong><h5>Seperti apa jaminan keamanan informasi dan data populasi dan responden?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseSeventeen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Survei dibangun patuh terhadap Undang-Undang Nomor 14 Tahun 2008 tentang Keterbukaan Informasi Publik serta Undang-Undang Republik Indonesia Nomor 16 Tahun 1997 tentang Statistik. Kami menjamin kerahasian informasi Anda.
                                                </div>
                                            </div>
                                        </div>


                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
                                                    <strong><h5>Darimana sumber informasi dan data saya diperoleh?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseTwenty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                KPK mendapatkan informasi Anda dari satuan pendidikan yang terpilih sebagai <em>sampling</em> atas persetujuan satuan pendidikan terpilih dengan mengirimkan data populasi secara <em>online.</em>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="pills-technical" role="tabpanel" aria-labelledby="pills-technical-tab">
                                <div className="container">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
                                                        <strong><h5>Bagaimana cara saya memulai mengisi survei?</h5></strong>
                                                    </button>
                                                </h2>
                                            <div id="collapseTwentyOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Untuk memulai mengisi survei, responden terlebih dahulu mengakses tautan survei yang diberikan melalui WhatsApp atau <em>email</em>. Kemudian, responden melakukan <em>login</em> dengan memasukkan nomor WhatsApp atau <em>email</em> penerima undangan survei. Selanjutnya, responden dapat mengikuti alur pengisian survei sesuai redaksi panduan pada WhatsApp atau <em>email</em> yang diterima responden atau penerima undangan survei.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
                                                        <strong><h5>Saya tidak bisa membuka tautan survei. Apa yang harus saya lakukan?</h5></strong>
                                                    </button>
                                                </h2>
                                            <div id="collapseTwentyTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Jika Anda tidak bisa membuka tautan survei, Anda perlu memastikan kembali bahwa perangkat (<em>handphone/laptop</em>/komputer) yang digunakan sudah terhubung dengan jaringan internet yang stabil. Jika masih tidak bisa, Anda dapat menghubungi dapat menghubungi PIC (<em>person in charge</em>) dari kegiatan ini, yaitu <strong>Sdr. Zulfadhli Nasution</strong> (Zulfadhli.Nasution@kpk.go.id) atau <strong>Sdri. Pipin Purbowati</strong> (Pipin.Purbowati@kpk.go.id).
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirty" aria-expanded="false" aria-controls="collapseThirty">
                                                    <strong><h5>
                                                    Berapa lama waktu pengisian survei?
                                                    </h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseThirty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Pengisian survei membutuhkan waktu pengisian selama kurang lebih 15-30 menit.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyNine" aria-expanded="false" aria-controls="collapseTwentyNine">
                                                    <strong><h5>
                                                    Apakah ada batas waktu pengisian survei?
                                                    </h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseTwentyNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Ya, ada. Tenggat pengisian survei ini adalah 30 September 2023 pukul 23.59 WIB.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySeven" aria-expanded="false" aria-controls="collapseTwentySeven">
                                                    <strong><h5>
                                                    Apakah responden wajib menjawab seluruh pertanyaan?
                                                    </h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseTwentySeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Untuk dapat menyelesaikan survei ini, seluruh pertanyaan yang diberikan dalam survei wajib untuk dijawab dengan sebaik-baiknya berdasarkan pengetahuan dan pengalaman responden.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                                                    <strong><h5>Bagaimana jika koneksi internet terputus pada saat sedang mengisi survei?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseTwentyFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Silakan mengeklik kembali tautan survei dan <em>login</em> kembali dengan WhatsApp atau <em>email</em> yang sama. Survei akan otomatis melanjutkan pertanyaan yang belum terisi.
                                                </div>
                                            </div>
                                        </div>


                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
                                                    <strong><h5>Apabila pengisian survei belum selesai, apakah bisa dilanjutkan kembali di waktu yang berbeda?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseTwentyThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Sistem pengisian survei dilakukan secara <em>online</em> yang akan menyimpan jawaban secara otomatis. Responden dapat melanjutkan pengisian survei kembali pada halaman survei yang terakhir kali diakses dengan menggunakan akun <em>login</em> yang sama.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
                                                    <strong><h5>Apakah pihak sekolah boleh mengarahkan responden pada saat pengisian survei?</h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseTwentyFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Pihak sekolah TIDAK diperbolehkan mengarahkan responden untuk menjawab pertanyaan survei. Responden diharapkan menjawab kuesioner dengan jujur dan sebenar-benarnya sehingga hasil survei dapat mencerminkan kondisi integritas pendidikan sesuai fakta.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
                                                    <strong><h5>
                                                    Apakah mengumpulkan orang tua dan siswa untuk meminta nomor kontak termasuk pengarahan?
                                                    </h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseTwentySix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Mengumpulkan orang tua dan siswa untuk meminta nomor kontak sekaligus sebagai ajang untuk sosialisasi bukan merupakan pengarahan yang dimaksud dalam pelaksanaan survei ini. Pengarahan yang tidak diperbolehkan yang dimaksud di sini adalah yang dapat mengurangi validitas hasil, yakni pengarahan dalam menjawab item-item pertanyaan pada kuesioner sehingga jawaban responden menjadi tidak independen dan tidak menggambarkan fakta sesungguhnya.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyEight" aria-expanded="false" aria-controls="collapseTwentyEight">
                                                    <strong><h5>
                                                    Apakah pengisian survei boleh dibantu atau diteruskan pengisiannya oleh pihak lain?
                                                    </h5></strong>
                                                </button>
                                            </h2>
                                            <div id="collapseTwentyEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                Pertanyaan yang tercantum pada survei sepenuhnya ditujukan untuk diisi oleh responden terpilih. Jika Anda menerima undangan partisipasi SPI Pendidikan, mohon berkenan untuk secara mandiri mengisi survei tersebut. 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </>
    )

}

export default FaqContent