import { dataviewTypes } from '../types'

const initialState = {
    dataSurvey: {},
    fieldDinamik: [
        'No.',
        'Progres Pengisian',
        'Status Data',
        'Keterangan',
        'Action',
    ],
    inputData: {
        isSortAsc: true,
        page: 1,
        pageSize: 10,
        search: '',
        sort: '',
        kuesionerId: ''
    },
    modalStatus: false
}

const alsusReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case dataviewTypes.GET_DATA_VIEW: {
            const { data, fieldDinamik } = action.payload
            return {
                ...state,
                dataSurvey: data,
                fieldDinamik: fieldDinamik,
                inputData: {
                    ...state.inputData,
                    // isSortAsc: true,
                    page: data.page ? data.page : 1,
                    pageSize: data.pageSize ? data.pageSize : 10,
                    // search: data.search ? data.search : '',
                    sort: data.sort ? data.sort : '',
                    // kuesionerId: data.kuesionerId
                }
            }
        }
        case dataviewTypes.CHANGE_INPUT_DATA_VIEW: {
            const { field, value } = action.payload
            return {
                ...state,
                inputData: {
                    ...state.inputData,
                    [field]: value,
                },
            }
        }
        case dataviewTypes.ON_RESET_DATA: {
            const { value } = action.payload
            return {
                ...state,
                dataSurvey: value,
            }
        }
        case dataviewTypes.OPEN_MODAL_VIEW: {
            return {
                ...state,
                modalStatus: !state.modalStatus
            }
        }
        default:
            return state
    }
}
export default alsusReducer
