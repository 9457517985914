import React, {useEffect, useState} from 'react'
import {Helmet} from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from "axios";
import {SERVICE} from "../../../lib/config";
import Swal from "sweetalert2";


const KuesionerRC = () => {

    const [dataRef, setDataRef] = useState({"jenisPemilu": "formrcdpr", "dapilDprdKab": "0"});
    const [data, setData] = useState([]);

    useEffect(() => {
        const uninterceptedAxiosInstance = axios.create();

        uninterceptedAxiosInstance.get(SERVICE.JAVA_SERVICE + '/kawal/dapildprdkab').then(value => {
            setData(value.data.data);
        });


    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleClick();
    };

    const handleClick = () => {
        if (dataRef.jenisPemilu === "formrcdprdkabkota") {
            if (dataRef.dapilDprdKab === "0") {
                Swal.fire({
                    icon: 'error',
                    title: "Harus Pilih Dapil"
                })
            } else {
                window.location.href = '/kuesioner/' + dataRef.jenisPemilu + dataRef.dapilDprdKab;
            }
        } else {
            window.location.href = '/kuesioner/' + dataRef.jenisPemilu;
        }
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setDataRef(prevState => ({
            ...prevState, [name]: value
        }))

        if (name === "jenisPemilu" && value !== "formrcdprdkabkota") {
            setDataRef(prevState => ({
                ...prevState, dapilDprdKab: ""
            }))
        }

    };

    return (
        <>
            <Helmet>
                <title>Real Count 2024</title>
                <meta name="SPI Pendidikan 2023" content="Helmet application"/>
            </Helmet>
            <body style={{overflow: 'hidden'}}>

            <div className="row text-justify" style={{marginTop: 120}}>

                <div className="row">
                    <div className="d-flex justify-content-center ">

                        <div className="col-12 col-md-6" style={{position: 'relative'}}>
                            {/* Background Image */}
                            <img
                                src={require('src/pages/kuesionerpages/qc_rc/Rectangle 5071.png')}
                                alt="background"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: 'cover',
                                    objectFit: 'cover',
                                    zIndex: 0,
                                }}
                            />

                            {/* Content */}
                            <div style={{position: 'relative', zIndex: 1, marginLeft: 50, marginRight: 50}}>
                                <img src={require('src/pages/kuesionerpages/qc_rc/Kawal.png')} alt="Logo Kawal"
                                     width='300' className="d-inline-block align-center"/>
                                <div className="container-sm" style={{marginBottom: 30, marginTop: 30}}>
                                    <h1 style={{fontFamily: 'Verdana'}}>Selamat Datang di</h1>
                                    <h1 style={{fontFamily: 'Verdana', color: '#16BDCF'}}>Real Count</h1>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group"
                                         style={{marginBottom: 130, marginTop: 130, paddingLeft: 20, paddingRight: 20}}>
                                        <select name="jenisPemilu" className="form-control" style={{
                                            marginBottom: 10,
                                            borderColor: 'gray',
                                            fontFamily: 'Verdana',
                                            color: '#16BDCF'
                                        }} onChange={handleChange}>
                                            <option value="formrcdpr">DPR RI</option>
                                            <option value="formrcdprdprov">DPRD Prov</option>
                                            <option value="formrcdprdkabkota">DPRD Kota/Kab</option>
                                            <option value="formrcpilpres">Presiden</option>
                                        </select>
                                        {dataRef.jenisPemilu === "formrcdprdkabkota" ?
                                            <select name="dapilDprdKab" className="form-control" style={{
                                                marginBottom: 10,
                                                borderColor: 'gray',
                                                fontFamily: 'Verdana',
                                                color: '#16BDCF'
                                            }} onChange={handleChange}>
                                                <option value="0">Pilih Dapil</option>
                                                {data.map(item => (
                                                    <option key={item.key} value={item.value}>
                                                        {item.value}
                                                    </option>
                                                ))}
                                            </select> : <></>}
                                        <button type="submit" className="btn btn-primary w-100"
                                                style={{fontFamily: 'Verdana', backgroundColor: '#16BDCF'}}>Masuk
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>


                        <div className="col-sm content-bg d-none d-sm-block">
                            <div className="text-center">
                                <h2 style={{fontFamily: 'Verdana', color: '#16BDCF'}}>Kawal perolehan suara
                                    Pemilu</h2>
                                <h2 style={{
                                    fontFamily: 'Verdana',
                                    color: '#16BDCF'
                                }}>dengan <strong>cepat</strong> dan <strong>akurat</strong></h2>
                            </div>
                            <img src={require('src/pages/kuesionerpages/qc_rc/ilustrasi.png')} alt="Ilustrasi"
                                 width='100%' className="d-inline-block align-center"/>
                        </div>
                    </div>
                </div>

            </div>
            </body>
        </>

    )

}

export default KuesionerRC