import { sidebarTypes } from '../types';

const sidebarActions = {
    onChange: (input) => (dispatch) => {
        const payload = { val: input }
        dispatch({ type: sidebarTypes.CHANGE_STATUS, payload });
    },

};
export default sidebarActions;
