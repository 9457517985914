import React from 'react'
import {Redirect} from 'react-router-dom'
import DashboardSPI from "./spi/DashboardSPI";
import DashboardSPIReal from './spi-real/DashboardSPIReal';
import DashboardAPJII from './APJII/DashboardAPJII';

const DashboardPages = () => {
    const switchDashboard = () => {
        let domainName = window.location.hostname;
        switch (domainName) {
            case "spipendidikan.kpk.go.id":
                return <>
                    <DashboardSPIReal/>
                    {/*<DashboardSPI/> */}
                </>
            case "localhost":
                return <>
                    {<DashboardAPJII/>}
                    {/*<DashboardSPIReal/>*/}
                    {/*<DashboardSPI/> */}
                    {/*<Redirect to='/login'/>*/}
                </>
            /*case "wesurvey.tenos.id":
                return <>
                    {<DashboardAPJII/>}
                </>*/
            default:
                return <Redirect to='/login'/>
        }
    }

    return (
        switchDashboard()
    )
}

export default DashboardPages
