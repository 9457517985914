import InputKodeQC from "./InputKodeQC";
import InputKodeRC from "./InputKodeRC"


const routesQC_RC = [
  { path: '/inputQC', name: 'Input Quick Count', component: InputKodeQC },
  { path: '/inputRC', name: 'Input Real Count', component: InputKodeRC }
];

export default routesQC_RC;
