import React from 'react'
import {useRef} from 'react';
import {Helmet} from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {LoadIndicator} from "../../../lib/loadindicator";
import axios from "axios";
import {SERVICE} from "../../../lib/config";
import Swal from "sweetalert2";


const KuesionerQC = () => {

    const inputRef = useRef(null);
    const selectRef = useRef(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleClick();
    };

    const handleClick = () => {
        // 👇 "inputRef.current.value" is input value
        LoadIndicator('Mengambil data...')

        let input = {
            id: inputRef.current.value
        }

        const uninterceptedAxiosInstance = axios.create();

        uninterceptedAxiosInstance.post(SERVICE.JAVA_SERVICE + "/qaccess/cek", input)
            .then((response) => {
                if (response.data.status === 200) {
                    if (response.data.data) {
                        Swal.close()
                        window.location.href = '/kuesioner/'+ selectRef.current.value + '/' + inputRef.current.value
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: response.data.message
                        })
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: response.data.message
                    })
                }
            })
    };

    return (
        <>
            <Helmet>
                <title>Quick Count 2024</title>
                <meta name="SPI Pendidikan 2023" content="Helmet application" />
            </Helmet>
            <body style={{ overflow: 'hidden' }}>

                <div className="row text-justify" style = {{marginTop:120}}>

                    <div className="row">
                        <div className="d-flex justify-content-center ">

                        <div className="col-12 col-md-6" style={{ position: 'relative' }}>
                            {/* Background Image */}
                            <img
                                src={require('src/pages/kuesionerpages/qc_rc/Rectangle 5071.png')}
                                alt="background"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: 'cover',
                                    objectFit: 'cover',
                                    zIndex: 0,
                                }}
                            />

                            {/* Content */}
                            <div style={{ position: 'relative', zIndex: 1, marginLeft:50, marginRight:50}}>
                                <img src={require('src/pages/kuesionerpages/qc_rc/Kawal.png')} alt="Logo Kawal" width='300' className="d-inline-block align-center" />
                                <div className="container-sm" style={{ marginBottom: 30, marginTop: 30 }}>
                                    <h1 style={{ fontFamily: 'Verdana'}}>Selamat Datang di</h1>
                                    <h1 style={{ fontFamily: 'Verdana', color: '#16BDCF' }}>Quick Count</h1>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group" style={{ marginBottom: 130, marginTop: 130, paddingLeft: 20, paddingRight: 20 }}>
                                        <select ref={selectRef} className="form-control" style={{ marginBottom: 10, borderColor: 'gray', fontFamily: 'Verdana', color: '#16BDCF' }}>
                                                <option value="formqcdpr">DPR RI</option>
                                                <option value="formqcdprdprov">DPRD Prov</option>
                                                <option value="formqcdprdkabkota">DPRD Kota/Kab</option>
                                                <option value="formqcpilpres">Presiden</option>
                                        </select>
                                        <input
                                            placeholder="Masukkan Kode Akses Anda"
                                            ref={inputRef}
                                            type="id"
                                            className="form-control flex-grow-1"
                                            id="InputID"
                                            style={{ marginBottom: 10, borderColor: 'gray', fontFamily: 'Verdana' }}
                                        />
                                        <button type="submit" className="btn btn-primary w-100" style={{ fontFamily: 'Verdana', backgroundColor:'#16BDCF'}}>Masuk</button>
                                    </div>
                                </form>
                            </div>
                        </div>


                            <div className="col-sm content-bg d-none d-sm-block">
                                <div className="text-center">
                                    <h2 style={{ fontFamily: 'Verdana' , color: '#16BDCF'}}>Kawal perolehan suara Pemilu</h2>
                                    <h2 style={{ fontFamily: 'Verdana' , color: '#16BDCF'}}>dengan <strong>cepat</strong> dan <strong>akurat</strong></h2>
                                </div>
                                <img src={require('src/pages/kuesionerpages/qc_rc/ilustrasi.png')} alt="Ilustrasi" width='100%' className="d-inline-block align-center" />
                            </div>
                        </div>      
                    </div>

                </div>
            </body>
        </>

    )

}

export default KuesionerQC