import React from 'react'
import {useHistory} from 'react-router-dom'
import {connect} from "react-redux";
import TheSidebar from './TheSidebar';
import TheHeader from './TheHeader';
import TheContent from './TheContent';


const TheLayout = ({isAuthenticated}) => {
    const history = useHistory();
    return (
        <>
            {isAuthenticated ?
                <div className="c-app c-default-layout">
                    <TheSidebar/>
                    <div className="c-wrapper">
                        <TheHeader/>
                        <div className="c-body">
                            <TheContent/>
                        </div>
                        {/* <TheFooter /> */}
                    </div>
                </div>
                :
                history.push('/landingpage')}
        </>
    )
}

const mapStateToProps = (state) => ({
    ...state.storageReducer,
});
const mapDisppatchToProps = {};
TheLayout.propTypes = {};

export default connect(mapStateToProps, mapDisppatchToProps)(TheLayout);
