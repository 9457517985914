import { sidebarTypes } from '../types'

const initialState = {
    sidebarShow: 'responsive'
}

const changeState = (state = { ...initialState }, action) => {
    switch (action.type) {
        case sidebarTypes.CHANGE_STATUS:
            const { val } = action.payload
            return {
                ...state,
                sidebarShow: val
            }
        default:
            return state
    }
}
export default changeState