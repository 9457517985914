// {
//     "App": {
//       "NAME": "FMS",
//       "VERSION": "cache-control",
//       "DEVELOPMENT": true
//     },
//     "SERVICE": {
//       "JAVA_SERVICE": process.env.END_POINT
//     }
//   }

export const SERVICE = {
  JAVA_SERVICE : process.env.REACT_APP_END_POINT
}