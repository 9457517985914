import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const DataFAQ = [
        {question:"Apakah pengisian kuesioner dapat dilakukan oleh lebih dari satu orang menggunakan username dan password yg sama?",
        answer:"Tidak ada batasan berapa orang yang bisa login bersamaan dalam 1 akun, namun untuk pengisian kuesioner, tidak bisa dilakukan secara bersamaan oleh dua orang atau lebih. Apabila pengisian dilakukan lebih dari satu orang maka harus bergantian/saling melanjutkan untuk mencegah terjadinya pengisian data yang ganda/double.",
        id:"FaqOne",
        data:"#FaqOne"},

        {question:"Apakah kuisioner harus diselesaikan baru tersimpan?",
        answer:"Tidak, setiap halaman kuisioner akan tersimpan otomatis apabila sudah klik “Next”. Pengisian kuisioner bisa di secara perlahan.",
        id:"FaqTwo",
        data:"#FaqTwo"},

        {question:"Apabila koneksi terputus atau kuesioner tertutup, apa bisa melanjutkan pengisian kuesioner? Atau perlu mengulang dari awal?",
        answer:"Pengisian Kuesioner tidak akan mengulang dari awal selama telah berpindah halaman, halaman terakhir yang tersimpan adalah halaman yang sudah terklik “Next Page”",
        id:"FaqThree",
        data:"#FaqThree"},

        {question:"Kalau bisa,bagaimana melanjutkannya?",
        answer:"Login kembali ke aplikasi, kemudian masuk ke menu “Data Kuesioner”, pilih Survey “IPLM 2023”, Kuesioner “IPLM Kota/Kab” dan klik “Tampil” sehingga akan menampilkan progress pengisian terakhir, Lalu klik tombol “Action” yang ada di paling kanan progress, dan klik “Edit”",
        id:"FaqFour",
        data:"#FaqFour"},

        {question:"Bagaimana apabila data yg diminta tidak ada?",
        answer:"Diupayakan terlebih dahulu dgn berkoordinasi dgn atasan/instansi terkait.",
        id:"FaqFive",
        data:"#FaqFive"},

        {question:"Kapan pengisian terakhir kuisioner?",
        answer:"4 Agustus 2023",
        id:"FaqSix",
        data:"#FaqSix"},

        {question:"Kenapa bisa gagal login?",
        answer:"Pastikan password anda telah benar dan anda memiliki koneksi internet yang stabil",
        id:"FaqSeven",
        data:"#FaqSeven"},

        {question:"Apakah aplikasi akan menutup ketika ditinggalkan?",
        answer:"Lama Session yang disediakan sistem adalah 1 Hari, setelah itu akun anda akan ter-logout otomatis",
        id:"FaqEight",
        data:"#FaqEight"},

        {question:"Kenapa saya tidak bisa klik next page setelah mengisi kuesioner?",
        answer:"Cek ulang format pengisian anda, bisa jadi ada field yang diisi dengan format yang salah, untuk pengisian anggaran, hanya isi menggunakan angka, tanpa titik dan koma",
        id:"FaqNine",
        data:"#FaqNine"},

]

const AccordionItem = ( {question, answer, id, data} ) => {
            
    return (
        <>
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={data} aria-expanded="true" aria-controls={id}>
                    <strong><h5>{question}</h5></strong>
                </button>
            </h2>
                    
            <div id={id} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div className="accordion-body" style={{color:'black'}}>
                    {answer}
                </div>
            </div>
        </div>
        </>     
    )

}


const FaqContent = () =>{


    return (
        <>
        <link rel="stylesheet" href="./customIPLM.css" />
        <div className="col-12 col-md-12 justify-content-center">
                <div className="accordion" id="accordionExample"style={{paddingRight:16, paddingLeft:16}}>

                {DataFAQ.map(
                    faq => <AccordionItem 
                    question={faq.question}
                    answer={faq.answer}
                    id={faq.id}
                    data={faq.data}
                    />
                )}

                                   
                </div>
        </div>
        </>
    )

}

export default FaqContent