import React from 'react'
import { Helmet } from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import FaqContent from './FaqContents.js'
import FooterSpi from './FooterSPI';
import NavbarSpi from './NavBar';
import './customSPIP.css';




const landingpageSPI = () => {
    return (
        <>
            <Helmet>
                <title>SPI Pendidikan 2023</title>
                <meta name="SPI Pendidikan 2023" content="Helmet application" />
            </Helmet>

            <body>

                <NavbarSpi/>
                <div className="row text-justify" style = {{marginTop:120}}>
                    <div style={{color:'black'}}>
                        <div className='col-12 col-md-9'>
                            <div className="container-sm" style = {{marginBottom:30, marginTop:30}}>
                                <p><strong>Selamat Datang.</strong></p>
                                    <strong><h2>Tentang Survei Penilaian Integritas Pendidikan 2023</h2><br/><br/></strong>
                                <p className="text-muted"><strong>Survei Penilaian Integritas Pendidikan</strong> atau disebut juga dengan SPI Pendidikan merupakan program yang secara resmi diselenggarakan oleh Komisi Pemberantasan Korupsi (KPK) dalam rangka melaksanakan amanat Undang-Undang Nomor 19 Tahun 2019 tentang Perubahan Kedua atas Undang-Undang Nomor 30 Tahun 2002 tentang Komisi Pemberantasan Korupsi khususnya Pasal 7. Mulai tahun 2023, SPI Pendidikan juga menjadi salah satu Program Prioritas Nasional yang berkaitan dengan Revolusi Mental dan Pembangunan Kebudayaan.</p>
                            </div>
                        </div>     
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-9 text-center">
                            <p></p>
                            <strong>
                            <h4>FAQ</h4>
                            </strong>
                            <p></p>
                        </div>

                        <div className="row justify mt-5">
                            <div className="col-lg-9">
                                <ul className="nav nav-tabs  nav-tabs-custom nav-justified justify-content-center faq-tab-box" id="pills-tab" role="tablist" style={{navLinkActiveColor:'hsla(120, 100%, 75%, 0.7)'}}>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-background-tab" data-bs-toggle="pill" data-bs-target="#pills-background" type="button" role="tab" aria-controls="pills-background" aria-selected="true">
                                            <strong>
                                                <span className="font-size-16">Umum</span>
                                            </strong>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-privacy_policy-tab" data-bs-toggle="pill" data-bs-target="#pills-privacy_policy" type="button" role="tab" aria-controls="pills-privacy_policy" aria-selected="false">
                                            <strong>
                                                <span className="font-size-16">Privasi Responden</span>
                                            </strong>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-technical-tab" data-bs-toggle="pill" data-bs-target="#pills-technical" type="button" role="tab" aria-controls="pills-technical" aria-selected="false">
                                            <strong>
                                                <span className="font-size-16">Teknis</span>
                                            </strong>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center" style = {{marginBottom:70}}>

                                <FaqContent/>
                        
                                <div className="col-sm content-bg d-none d-sm-block" style={{minHeight:595 }}>
                                    <img src={require('src/pages/landingpages/spi/Sideart-1.png')}  alt="Sideart-1"  width='100%' className="d-inline-block align-center"/>
                                    <img src={require('src/pages/landingpages/spi/Sideart-2.png')}  alt="Sideart-2" width='100%' className="d-inline-block align-center"/>
                                    <img src={require('src/pages/landingpages/spi/Sideart-3.png')}  alt="Sideart-3"  width='100%' className="d-inline-block align-center"/>				
                                </div>
                            </div>
                        </div>

                    


                        <FooterSpi/>
                </div>
            </body>
        </>

    )

}

export default landingpageSPI