import { SERVICE } from '../../lib/config';
import axios from 'axios';
import { LoadIndicator } from '../../lib/loadindicator'
import Swal from 'sweetalert2';
import storageTypes from "../types/storageTypes";

const storageActions = {
    getAccessTokenResponse: (input) => async (dispatch) => {
        try {
            LoadIndicator('Mengambil data...')
            const uninterceptedAxiosInstance = axios.create();
            await uninterceptedAxiosInstance
                .post(SERVICE.JAVA_SERVICE + "/auth/token", new URLSearchParams(input).toString())
                .then((response) => {
                    if (response.data.status === 200) {
                        const payload = {data: response.data.data.token, isAuthenticated: true}
                        dispatch({type: storageTypes.GET_ACCESS_TOKEN_RESPONSE, payload})
                        Swal.close()
                        return payload
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: response.data.message
                        })
                    }


                })

        } catch (e) {
            const payload = { data: [], isAuthenticated: false };
            dispatch({ type: storageTypes.GET_ACCESS_TOKEN_RESPONSE, payload });
            return payload;
        }
    },
    setAccessTokenResponse: (data) => async (dispatch) => {
        try {

            const payload = { data: data , isAuthenticated: true}
            dispatch({ type: storageTypes.SET_ACCESS_TOKEN_RESPONSE, payload })
            return payload

        } catch (e) {
            const payload = { data: [], isAuthenticated: false };
            dispatch({ type: storageTypes.SET_ACCESS_TOKEN_RESPONSE, payload });
            return payload;
        }
    },
    doLogout: () => async (dispatch) => {
        try {

            const payload = {}
            dispatch({ type: storageTypes.DO_LOGOUT, payload })
            return payload

        } catch (e) {
            const payload = {};
            dispatch({ type: storageTypes.DO_LOGOUT, payload });
            return payload;
        }
    }
};
export default storageActions;
