import React from 'react'
import {Helmet} from "react-helmet";
import NavbarSpi from './NavBar';
import FooterSpi from './FooterSpi';
import CreateGroupedTable from './CreateGroupedTable';
import AggregatedTable from "./AgregatedTable";
import GrafikSPI from "./GrafikSPI";
import GeoChart from "./GeoChart";


const DashboardSPIReal = () => {
    return (
        <>
            <Helmet>
                <title>SPI Pendidikan 2023</title>
                <meta name="SPI Pendidikan 2023" content="Helmet application"/>
            </Helmet>
            <NavbarSpi/>

            <div className="row text-justify">
                <div style={{color: 'black'}}>
                    <div className='col-12 col-md-9'>
                        <div className="container-sm" style={{marginBottom: 30, marginTop: 160}}>
                            <p><strong>Selamat Datang.</strong></p>
                            <strong><h2>Tentang Survei Penilaian Integritas Pendidikan 2023</h2><br/><br/></strong>
                            <p className="text-muted"><strong>Survei Penilaian Integritas Pendidikan</strong> atau
                                disebut juga dengan SPI Pendidikan merupakan program yang secara resmi diselenggarakan
                                oleh Komisi Pemberantasan Korupsi (KPK) dalam rangka melaksanakan amanat Undang-Undang
                                Nomor 19 Tahun 2019 tentang Perubahan Kedua atas Undang-Undang Nomor 30 Tahun 2002
                                tentang Komisi Pemberantasan Korupsi khususnya Pasal 7. Mulai tahun 2023, SPI Pendidikan
                                juga menjadi salah satu Program Prioritas Nasional yang berkaitan dengan Revolusi Mental
                                dan Pembangunan Kebudayaan.</p>
                        </div>
                    </div>
                </div>

                {/*<div className="row justify-content-center mt-5" style = {{marginBottom:130, marginTop:130}}>
                <iframe title="dashboard" src="https://lookerstudio.google.com/embed/reporting/feabb290-97ad-460a-8505-38cab17d984a/page/lSgUD" style={{height:900}}></iframe>
                </div>*/}

                <div className="row justify mt-5">
                    <div className="col-lg-9">
                        <ul className="nav nav-tabs  nav-tabs-custom nav-justified justify-content-center faq-tab-box"
                            id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-chart-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-chart" type="button" role="tab"
                                        aria-controls="pills-chart" aria-selected="true">
                                    <strong>
                                        <span className="font-size-16">Grafik</span>
                                    </strong>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-data-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-data" type="button" role="tab" aria-controls="pills-data"
                                        aria-selected="false">
                                    <strong>
                                        <span className="font-size-16">Data Perolehan</span>
                                    </strong>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-recap-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-recap" type="button" role="tab"
                                        aria-controls="pills-recap" aria-selected="false">
                                    <strong>
                                        <span className="font-size-16">Rekap Perolehan</span>
                                    </strong>
                                </button>
                            </li>
                            {/*<li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-map-tab" data-bs-toggle="pill"
                                        data-bs-target="#pills-map" type="button" role="tab" aria-controls="pills-map"
                                        aria-selected="false">
                                    <strong>
                                        <span className="font-size-16">Peta</span>
                                    </strong>
                                </button>
                            </li>*/}
                        </ul>
                    </div>
                </div>


                <div className="row justify-content-center mt-5" style={{marginBottom: 30, marginTop: 130}}>
                    <div className="col-12 col-md-9" style={{marginLeft: 30, marginRight: 30}}>
                        <div className="tab-content pt-3" id="pills-tabContent">
                            <div className="tab-pane fade active show" id="pills-chart" role="tabpanel"
                                 aria-labelledby="pills-chart-tab">
                                <GrafikSPI/>
                            </div>


                            <div className="tab-pane fade" id="pills-data" role="tabpanel"
                                 aria-labelledby="pills-data-tab">
                                <div className="container">
                                    <div style={{marginBottom: 40}}>
                                        <h3><strong>Data Perolehan</strong></h3>
                                    </div>

                                    <div>
                                        <CreateGroupedTable/>
                                    </div>

                                </div>
                            </div>


                            <div className="tab-pane fade" id="pills-recap" role="tabpanel"
                                 aria-labelledby="pills-recap-tab">


                                <AggregatedTable/>


                            </div>

                            
                            {/* <div className="tab-pane fade" id="pills-map" role="tabpanel"
                                 aria-labelledby="pills-map-tab">
                                <div className="container">
                                <div style={{marginBottom: 40}}>
                                        <h3><strong>Capaian Target per Provinsi</strong></h3>
                                    </div>
                                    
                                    <GeoChart/>
                                </div>
                            </div>*/}
                        </div>
                    </div>

                    <div className="col-sm content-bg d-none d-sm-block" style={{minHeight: 595}}>
                        <img src={require('src/pages/landingpages/spi/Sideart-1.png')} alt="Sideart-1" width='100%'
                             className="d-inline-block align-center"/>
                        <img src={require('src/pages/landingpages/spi/Sideart-2.png')} alt="Sideart-2" width='100%'
                             className="d-inline-block align-center"/>
                        <img src={require('src/pages/landingpages/spi/Sideart-3.png')} alt="Sideart-3" width='100%'
                             className="d-inline-block align-center"/>
                    </div>


                </div>

                <FooterSpi/>

            </div>
        </>

    )

}

export default DashboardSPIReal