import Swal from 'sweetalert2'

export const LoadIndicator = (message) => {
    return Swal.fire({
        allowOutsideClick: false,
        title: message,
        showConfirmButton: false,
        imageUrl: '/logo/loading.gif',
        imageWidth: 300,
        imageHeight: 200,
        imageAlt: 'Custom image',
    })
};