import React, {useEffect, useState} from 'react'
import DataTable from 'react-data-table-component';
import axios from "axios";
import {SERVICE} from "../../../lib/config";
import Swal from "sweetalert2";
import { CButton, CSelect } from '@coreui/react';

const AggregatedTable = () => {
    const [groupBy, setGroupBy] = useState('ALL'); // Default groupBy value
    const [selectedJenjang, setSelectedJenjang] = useState('');
    const [selectedProvinsi, setSelectedProvinsi] = useState('');
    const [selectedRegional, setSelectedRegional] = useState('');
    const [filteredProvinsiOptions, setFilteredProvinsiOptions] = useState([]);
    const [selectedKategori, setSelectedKategori] = useState('');
    const [tableData, setTableData] = useState([{
        "group": "",
        "total_populasi": 0,
        "total_blast": 0,
        "total_target_perolehan": 0,
        "total_jumlah_perolehan": 0,
        "ketercapaian": 0
    }]);

    const [provinsiOptions, setProvinsiOptions] = useState([]);
    const [jenjangOptions, setJenjangOptions] = useState([]);
    const [regionalOptions, setRegionalOptions] = useState([]);
    const [kategoriOptions, setKategoriOptions] = useState([]);

    useEffect(() => {
        const uninterceptedAxiosInstance = axios.create();
        uninterceptedAxiosInstance
          .get(SERVICE.JAVA_SERVICE + '/restdata/suffix/kategoriSPIPKPK')
          .then((response) => {
            if (response.data.status === 200) {
              const kategoriData = response.data.data.map((item) => item.kategori_responden);
              setKategoriOptions(kategoriData);
            } else {
              Swal.fire({
                icon: 'error',
                title: response.data.message,
              });
            }
          })
          .catch((reason) => {
            Swal.fire({
              icon: 'error',
              title: reason,
            });
          });
    }, []);


    useEffect(() => {
        const uninterceptedAxiosInstance = axios.create();
        uninterceptedAxiosInstance
          .get(SERVICE.JAVA_SERVICE + '/restdata/suffix/regionalSPIPKPK')
          .then((response) => {
            if (response.data.status === 200) {
              const regionalData = response.data.data.map((item) => item.regional);
              setRegionalOptions(regionalData);
            } else {
              Swal.fire({
                icon: 'error',
                title: response.data.message,
              });
            }
          })
          .catch((reason) => {
            Swal.fire({
              icon: 'error',
              title: reason,
            });
          });
    },[])

    useEffect(() => {
        const uninterceptedAxiosInstance = axios.create();
          uninterceptedAxiosInstance
          .get(SERVICE.JAVA_SERVICE + '/restdata/suffix/jenjangSPIPKPK')
          .then((response) => {
            if (response.data.status === 200) {
              const jenjangData = response.data.data.map((item) => item.jenjang);
              setJenjangOptions(jenjangData);
            } else {
              Swal.fire({
                icon: 'error',
                title: response.data.message,
              });
            }
          })
          .catch((reason) => {
            Swal.fire({
              icon: 'error',
              title: reason,
            });
          });
    },[])

    useEffect(() => {
        const uninterceptedAxiosInstance = axios.create();
        uninterceptedAxiosInstance
          .get(SERVICE.JAVA_SERVICE + '/restdata/suffix/provinsiSPIPKPK')
          .then((response) => {
            if (response.data.status === 200) {
              const provinsiData = response.data.data.map((item) => item);
              setProvinsiOptions(provinsiData);
            } else {
              Swal.fire({
                icon: 'error',
                title: response.data.message,
              });
            }
          })
          .catch((reason) => {
            Swal.fire({
              icon: 'error',
              title: reason,
            });
          });
    },[])

    useEffect(() => {
        if (selectedRegional) {
          const filteredProvinsi = provinsiOptions.filter(
            (option) => option.regional === selectedRegional
          );
          setFilteredProvinsiOptions(filteredProvinsi);
        } else {
          setFilteredProvinsiOptions([]);
        }
      }, [selectedRegional, provinsiOptions]);


    useEffect(() => {

        const uninterceptedAxiosInstance = axios.create();
        const input = {
            surveyId: '33',
            suffixRestData: 'dashboardSPIPendidikan',
            groupRekap: groupBy,
            filter: {}
          };
        
          if (selectedJenjang !== '') {
            input.filter['jenjang'] = [selectedJenjang];
          }
    
          if (selectedProvinsi !== '') {
            input.filter['101_provinsi'] = [selectedProvinsi];
          }

          if (selectedRegional !== '') {
            input.filter['regional'] = [selectedRegional];
          }
    
          if (selectedKategori !== '') {
            input.filter['kategori_responden'] = [selectedKategori];
          }
      

        uninterceptedAxiosInstance.post(SERVICE.JAVA_SERVICE + "/dashboardpage/spi/rekap", input)
            .then((response) => {
                if (response.data.status === 200) {

                    setTableData(response.data.data)
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: response.data.message
                    })
                }
            })
            .catch(reason => {
                Swal.fire({
                    icon: 'error',
                    title: reason
                })
            })
    }, [groupBy])

    
      const subHeaderComponentMemo = React.useMemo(() => {
        const handleResetFilters = () => {
            setSelectedJenjang('');
            setSelectedProvinsi('');
            setSelectedRegional('');
            setSelectedKategori('');
            const uninterceptedAxiosInstance = axios.create();
            let input = {
                "surveyId": "33",
                "suffixRestData": "dashboardSPIPendidikan",
                "groupRekap": groupBy,
            }

            uninterceptedAxiosInstance.post(SERVICE.JAVA_SERVICE + "/dashboardpage/spi/rekap", input)
                .then((response) => {
                    if (response.data.status === 200) {

                        setTableData(response.data.data)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: response.data.message
                        })
                    }
                })
                .catch(reason => {
                    Swal.fire({
                        icon: 'error',
                        title: reason
                    })
                })
        };


        const handleApplyFilters = () => {
          const uninterceptedAxiosInstance = axios.create();
          const input = {
            surveyId: '33',
            suffixRestData: 'dashboardSPIPendidikan',
            groupRekap: groupBy,
            filter: {}
          };

          if (selectedJenjang !== '') {
            input.filter['jenjang'] = [selectedJenjang];
          }
    
          if (selectedProvinsi !== '') {
            input.filter['101_provinsi'] = [selectedProvinsi];
          }

          if (selectedRegional !== '') {
            input.filter['regional'] = [selectedRegional];
          }
    
          if (selectedKategori !== '') {
            input.filter['kategori_responden'] = [selectedKategori];
          }
      
          uninterceptedAxiosInstance
            .post(SERVICE.JAVA_SERVICE + '/dashboardpage/spi/rekap', input)
            .then((response) => {
              if (response.data.status === 200) {
                setTableData(response.data.data);
              } else {
                Swal.fire({
                  icon: 'error',
                  title: response.data.message,
                });
              }
            })
            .catch((reason) => {
              Swal.fire({
                icon: 'error',
                title: reason,
              });
            });
        };
      
        return (
          <div style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <CSelect
              style={{ marginRight: '10px' }}
              onChange={(event) => setSelectedJenjang(event.target.value)}
              value={selectedJenjang}
            >
              <option value="">Pilih Jenjang</option>
              {jenjangOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </CSelect>
            <CSelect
              style={{ marginRight: '10px' }}
              onChange={(event) => setSelectedKategori(event.target.value)}
              value={selectedKategori}
            >
              <option value="">Pilih Kategori</option>
              {kategoriOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </CSelect>
            <CSelect
              style={{ marginRight: '10px' }}
              onChange={(event) => setSelectedRegional(event.target.value)}
              value={selectedRegional}
            >
              <option value="">Pilih Regional</option>
              {regionalOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
              </CSelect>
              <CSelect
                style={{ marginRight: '10px' }}
                onChange={(event) => setSelectedProvinsi(event.target.value)}
                value={selectedProvinsi}
                disabled={!selectedRegional} // Disable when no "Regional" is selected
                >
                <option value="">Pilih Provinsi</option>
                {filteredProvinsiOptions.map((option) => (
                    <option value={option['101_provinsi']}>
                    {option['101_provinsi']}
                    </option>
                ))}
            </CSelect>
      
            <CButton
              style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}
              onClick={handleApplyFilters}
            >
              Filter
            </CButton>
            <CButton
                style={{ backgroundColor: 'red', color: 'white' }}
                onClick={handleResetFilters} // Bind the reset function to the button
            >
                X
            </CButton>
          </div>
        );
      }, [groupBy, selectedJenjang, selectedProvinsi, selectedRegional, selectedKategori, jenjangOptions, filteredProvinsiOptions, regionalOptions, kategoriOptions]);
      





    // Define table columns
    const tableColumns = [
        {selector: row => row.group, name: 'Group'},
        {selector: row => row.total_populasi, name: 'Total Populasi', cell: row => row.total_populasi.toLocaleString(),},
        {selector: row => row.total_blast, name: 'Total Blast', cell: row => row.total_blast.toLocaleString(),},
        {selector: row => row.total_target_perolehan, name: 'Total Target Perolehan', cell: row => row.total_target_perolehan.toLocaleString(),},
        {selector: row => row.total_jumlah_perolehan, name: 'Total Jumlah Perolehan',cell: row => row.total_jumlah_perolehan.toLocaleString(),},
        {selector: row => row.ketercapaian, name: '%Ketercapaian', sortable: true, }
    ];


    return (
        <div className="container">
            <div style={{marginBottom: 40}}>
                <h3><strong>Rekap Perolehan</strong></h3>
            </div>
            <div className="row mb-4">
                <div className="col-md-4">
                    <select className="form-select" aria-label="Default select example"
                            onChange={event => setGroupBy(event.target.value)}>
                        <option value="ALL">Semua Populasi</option>
                        <option value="regional">Regional</option>
                        <option value="101_provinsi">Provinsi</option>
                        <option value="jenjang">Jenjang</option>
                        <option value="kategori_responden">Kategori Responden</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <DataTable
                        columns={tableColumns}
                        data={tableData}
                        pagination
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                    />
                </div>
            </div>
        </div>
    );


}

export default AggregatedTable