import React from 'react'
import {Redirect} from 'react-router-dom'
import LandingpageSPI from "./spi/landingpageSPI";
import LandingPageWeSurvey from './wesurvey/LandingpageWeSurvey';

const LandingPages = () => {
    const switchLanding = () => {
        let domainName = window.location.hostname;
        switch (domainName) {
            case "spipendidikan.kpk.go.id":
                return <>
                    <LandingpageSPI/>
                </>
            case "localhost":
                return <>
                    <LandingPageWeSurvey/>
                    {/*<Redirect to='/login'/>*/}
                </>
            default:
                return <Redirect to='/login'/>
        }
    }

    return (
        switchLanding()
    )
}

export default LandingPages
