import React from 'react'
import { Helmet } from "react-helmet";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import FaqContent from './FaqContents';



function LandingpageIPLM() {
    return (
        <>
            <Helmet>
                <title>IPLM</title>
                <meta name="IPLM" content="Helmet application" />
            </Helmet>
            <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lato" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Montserrat" />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
            

            <nav className="navbar navbar-expand-lg bg-body-primary">
                <div className="container-fluid">
                    <a className="navbar-brand">
                        <div className="mx-100">
                            <img src="https://i.ibb.co/PzLNjF0/Logo-Perpusnas.png" alt="Logo-Perpusnas" width="auto" height="75" className="d-inline-block align-center" />
                        </div>
                    </a>

                    <a className="navbar-brand">
                        <div className="mx-100">
                            <img src="https://i.ibb.co/LC2jdNB/Logo-Indekstat-removebg-preview.png" alt="Logo-Indekstat" width="auto" height="30" className="d-inline-block align-center" />
                        </div>
                    </a>
                </div>
            </nav>

            <body>

                <header className="w3-container w3-center" style={{ paddingTop: 128, paddingBottom: 60, paddingRight: 16, paddingLeft: 16, color: "hsla(78, 51%, 90%, 1)", backgroundColor: "hsla(240, 41%, 33%, 1)" }}>
                    <h1 className="w3-margin w3-jumbo d-none d-sm-block">Indeks Pembangunan Literasi Masyarakat</h1>
                    <h1 className="w3-margin d-block d-sm-none"><strong>Indeks Pembangunan Literasi Masyarakat</strong></h1>
                    <p className="w3-xlarge">Pengukuran terhadap usaha yang dilaksanakan oleh pemerintah Provinsi dan Kabupaten/Kota dalam membina dan mengembangkan perpustakaan sebagai wahana belajar sepanjang hayat.</p>
                    <a href="/login" className="btn btn-primary btn-lg active" role="button" aria-pressed="true">Isi Sekarang</a>
                </header>

                <div className="w3-row-padding w3-padding-64 w3-container">
    
                            <div className="col-lg-12">
                                <ul className="nav nav-tabs  nav-tabs-custom nav-justified justify-content-center faq-tab-box" id="pills-tab" role="tablist" style={{navLinkActiveColor:'hsla(120, 100%, 75%, 0.7)'}}>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-panduan-tab" data-bs-toggle="pill" data-bs-target="#pills-panduan" type="button" role="tab" aria-controls="pills-panduan" aria-selected="true">
                                            <strong>
                                                <div style={{fontSize:24}}>Panduan Pengisian</div>
                                            </strong>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-faq-tab" data-bs-toggle="pill" data-bs-target="#pills-faq" type="button" role="tab" aria-controls="pills-faq" aria-selected="false">
                                            <strong>
                                            <div style={{fontSize:24}}>Pertanyaan Seputar Teknis</div>
                                            </strong>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        

                        <div className="tab-content pt-3" id="pills-tabContent">
                            <div className="tab-pane fade active show" id="pills-panduan" role="tabpanel" aria-labelledby="pills-panduan">
                                <div className="container">
                                <div className="w3-content">
                                    <div className="w3-twothird">
                                        <h1>Panduan Pengisian</h1>

                                        <p className="w3-text-grey">1. Klik tombol "Isi Sekarang" yang ada di page ini</p>

                                        <p className="w3-text-grey">2. Masukkan username dan password yang diberikan kepada PIC Daerah</p>

                                        <p className="w3-text-grey">3. Disarankan untuk mengganti password pada kolom menu yang telah tersedia</p>

                                        <p className="w3-text-grey">4. Melakukan pengisian kuesioner</p>

                                        <p className="w3-text-grey">5. Kuesioner yang telah diisi sebagian akan tersimpan sehingga responden tidak perlu mengulang pengisian dari awal</p>

                                        <p className="w3-text-grey">6. Kuesioner sudah tersimpan apabila sudah ada tanda ceklis</p>
                                    </div>

                                    <div className="w3-third w3-center">
                                        <i className="fa fa-book w3-padding-64" style={{ fontSize: 200, color: 'hsla(155, 67%, 45%, 1)' }}></i>
                                    </div>
                                </div>

                                </div>
                            </div>
                        </div>

                        <div className="tab-content pt-3" id="pills-tabContent">
                            <div className="tab-pane fade" id="pills-faq" role="tabpanel" aria-labelledby="pills-faq">
                                <div className="container">
                                <div className="w3-content">
                                    <div className="w3-container w3-center">
                                    <h1>FAQ</h1>
                                    <h3>Pertanyaan-pertanyaan Seputar Teknis</h3>
                                        <FaqContent />
                                    </div>
                                    
                                </div>

                                </div>
                            </div>
                        </div>

                    
                </div>

                



               


                <div className="w3-container w3-black w3-center w3-opacity w3-padding-64">
                    <h1 className="w3-margin w3-xlarge">Transformasi Perpustakaan Berbasis Inklusi Sosial, Solusi Cerdas Pemulihan Ekonomi Masyarakat Pasca Pandemi Covid-19</h1>
                </div>

                <footer className="w3-container w3-padding-64 w3-center w3-opacity">
                    <p>Powered by <a href="https://www.indekstat.com" target="_blank">Indekstat</a></p>
                </footer>


            </body>
        </>

    );

}

export default LandingpageIPLM