export const dataDashboard = [

    {"npsn":"1033","101_provinsi":"Jawa Barat","102_kab_kota":"Bandung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"Universitas Pendidikan Indonesia","jenjang":"PT","kategori_responden":"Mahasiswa","jumlah_populasi":36790,"jumlah_blast":635,"target_perolehan":127,"Suffixurl":"pretest2-spipendidikanmahasiswa","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1033","101_provinsi":"Jawa Barat","102_kab_kota":"Bandung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"Universitas Pendidikan Indonesia","jenjang":"PT","kategori_responden":"Pimpinan","jumlah_populasi":18,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikanrektor","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1033","101_provinsi":"Jawa Barat","102_kab_kota":"Bandung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"Universitas Pendidikan Indonesia","jenjang":"PT","kategori_responden":"Dosen","jumlah_populasi":1409,"jumlah_blast":268,"target_perolehan":54,"Suffixurl":"pretest2-spipendidikandosen","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1034","101_provinsi":"Jawa Barat","102_kab_kota":"Bandung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"UIN Sunan Gunung Djati Bandung","jenjang":"PT","kategori_responden":"Mahasiswa","jumlah_populasi":7474,"jumlah_blast":129,"target_perolehan":26,"Suffixurl":"pretest2-spipendidikanmahasiswa","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1034","101_provinsi":"Jawa Barat","102_kab_kota":"Bandung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"UIN Sunan Gunung Djati Bandung","jenjang":"PT","kategori_responden":"Pimpinan","jumlah_populasi":17,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikanrektor","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1034","101_provinsi":"Jawa Barat","102_kab_kota":"Bandung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"UIN Sunan Gunung Djati Bandung","jenjang":"PT","kategori_responden":"Dosen","jumlah_populasi":783,"jumlah_blast":149,"target_perolehan":30,"Suffixurl":"pretest2-spipendidikandosen","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1035","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"IPB University","jenjang":"PT","kategori_responden":"Mahasiswa","jumlah_populasi":13107,"jumlah_blast":227,"target_perolehan":46,"Suffixurl":"pretest2-spipendidikanmahasiswa","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1035","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"IPB University","jenjang":"PT","kategori_responden":"Pimpinan","jumlah_populasi":191,"jumlah_blast":10,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikanrektor","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1035","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"IPB University","jenjang":"PT","kategori_responden":"Dosen","jumlah_populasi":1335,"jumlah_blast":254,"target_perolehan":51,"Suffixurl":"pretest2-spipendidikandosen","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1037","101_provinsi":"Lampung","102_kab_kota":"Lampung","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"Universitas Bandar Lampung","jenjang":"PT","kategori_responden":"Mahasiswa","jumlah_populasi":5008,"jumlah_blast":87,"target_perolehan":18,"Suffixurl":"pretest2-spipendidikanmahasiswa","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1037","101_provinsi":"Lampung","102_kab_kota":"Lampung","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"Universitas Bandar Lampung","jenjang":"PT","kategori_responden":"Dosen","jumlah_populasi":152,"jumlah_blast":29,"target_perolehan":6,"Suffixurl":"pretest2-spipendidikandosen","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1037","101_provinsi":"Lampung","102_kab_kota":"Lampung","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"Universitas Bandar Lampung","jenjang":"PT","kategori_responden":"Pimpinan","jumlah_populasi":22,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikanrektor","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1038","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"Universitas Kristen Papua","jenjang":"PT","kategori_responden":"Mahasiswa","jumlah_populasi":468,"jumlah_blast":8,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikanmahasiswa","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1038","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"Universitas Kristen Papua","jenjang":"PT","kategori_responden":"Dosen","jumlah_populasi":37,"jumlah_blast":7,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikandosen","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1038","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"Universitas Kristen Papua","jenjang":"PT","kategori_responden":"Pimpinan","jumlah_populasi":15,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikanrektor","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1039","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"IAIN Sorong","jenjang":"PT","kategori_responden":"Mahasiswa","jumlah_populasi":1380,"jumlah_blast":24,"target_perolehan":5,"Suffixurl":"pretest2-spipendidikanmahasiswa","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1039","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"IAIN Sorong","jenjang":"PT","kategori_responden":"Dosen","jumlah_populasi":70,"jumlah_blast":13,"target_perolehan":3,"Suffixurl":"pretest2-spipendidikandosen","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1013","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMKN 1 Kalianda","jenjang":"SMA","kategori_responden":"Siswa","jumlah_populasi":271,"jumlah_blast":19,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikansma","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1013","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMKN 1 Kalianda","jenjang":"SMA","kategori_responden":"Orang Tua","jumlah_populasi":169,"jumlah_blast":19,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1013","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMKN 1 Kalianda","jenjang":"SMA","kategori_responden":"Guru","jumlah_populasi":50,"jumlah_blast":41,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1013","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMKN 1 Kalianda","jenjang":"SMA","kategori_responden":"Pimpinan","jumlah_populasi":5,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1030","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MAN Kota Sorong","jenjang":"SMA","kategori_responden":"Siswa","jumlah_populasi":160,"jumlah_blast":11,"target_perolehan":3,"Suffixurl":"pretest2-spipendidikansma","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1030","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MAN Kota Sorong","jenjang":"SMA","kategori_responden":"Orang Tua","jumlah_populasi":160,"jumlah_blast":11,"target_perolehan":3,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1030","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MAN Kota Sorong","jenjang":"SMA","kategori_responden":"Guru","jumlah_populasi":48,"jumlah_blast":40,"target_perolehan":8,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1030","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MAN Kota Sorong","jenjang":"SMA","kategori_responden":"Pimpinan","jumlah_populasi":5,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1003","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 2 Cisarua","jenjang":"SMP","kategori_responden":"Siswa","jumlah_populasi":586,"jumlah_blast":23,"target_perolehan":5,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1003","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 2 Cisarua","jenjang":"SMP","kategori_responden":"Orang Tua","jumlah_populasi":586,"jumlah_blast":25,"target_perolehan":5,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1003","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 2 Cisarua","jenjang":"SMP","kategori_responden":"Guru","jumlah_populasi":24,"jumlah_blast":17,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1003","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 2 Cisarua","jenjang":"SMP","kategori_responden":"Pimpinan","jumlah_populasi":5,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1009","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 14 Bandar Lampung","jenjang":"SMP","kategori_responden":"Siswa","jumlah_populasi":479,"jumlah_blast":19,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1009","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 14 Bandar Lampung","jenjang":"SMP","kategori_responden":"Orang Tua","jumlah_populasi":479,"jumlah_blast":20,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1009","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 14 Bandar Lampung","jenjang":"SMP","kategori_responden":"Guru","jumlah_populasi":66,"jumlah_blast":46,"target_perolehan":10,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1009","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 14 Bandar Lampung","jenjang":"SMP","kategori_responden":"Pimpinan","jumlah_populasi":4,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1027","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MTsN 2 Bandar Lampung","jenjang":"SMP","kategori_responden":"Siswa","jumlah_populasi":757,"jumlah_blast":30,"target_perolehan":6,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1027","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MTsN 2 Bandar Lampung","jenjang":"SMP","kategori_responden":"Orang Tua","jumlah_populasi":757,"jumlah_blast":32,"target_perolehan":7,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1027","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MTsN 2 Bandar Lampung","jenjang":"SMP","kategori_responden":"Guru","jumlah_populasi":88,"jumlah_blast":61,"target_perolehan":13,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1027","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MTsN 2 Bandar Lampung","jenjang":"SMP","kategori_responden":"Pimpinan","jumlah_populasi":6,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1001","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN Cisarua 01 ","jenjang":"SD","kategori_responden":"Siswa","jumlah_populasi":219,"jumlah_blast":54,"target_perolehan":11,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1001","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN Cisarua 01 ","jenjang":"SD","kategori_responden":"Orang Tua","jumlah_populasi":219,"jumlah_blast":54,"target_perolehan":11,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1001","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN Cisarua 01 ","jenjang":"SD","kategori_responden":"Guru","jumlah_populasi":21,"jumlah_blast":21,"target_perolehan":5,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1001","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN Cisarua 01 ","jenjang":"SD","kategori_responden":"Pimpinan","jumlah_populasi":1,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1002","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN Julang","jenjang":"SD","kategori_responden":"Siswa","jumlah_populasi":127,"jumlah_blast":31,"target_perolehan":7,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1002","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN Julang","jenjang":"SD","kategori_responden":"Orang Tua","jumlah_populasi":127,"jumlah_blast":31,"target_perolehan":7,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1002","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN Julang","jenjang":"SD","kategori_responden":"Guru","jumlah_populasi":19,"jumlah_blast":19,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1002","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN Julang","jenjang":"SD","kategori_responden":"Pimpinan","jumlah_populasi":3,"jumlah_blast":3,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1008","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN 2 Sukaraja","jenjang":"SD","kategori_responden":"Siswa","jumlah_populasi":141,"jumlah_blast":34,"target_perolehan":7,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1008","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN 2 Sukaraja","jenjang":"SD","kategori_responden":"Orang Tua","jumlah_populasi":140,"jumlah_blast":34,"target_perolehan":7,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1008","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN 2 Sukaraja","jenjang":"SD","kategori_responden":"Guru","jumlah_populasi":19,"jumlah_blast":19,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1008","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN 2 Sukaraja","jenjang":"SD","kategori_responden":"Pimpinan","jumlah_populasi":4,"jumlah_blast":4,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1016","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SD Al Irsyad Kota Sorong","jenjang":"SD","kategori_responden":"Siswa","jumlah_populasi":166,"jumlah_blast":41,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1016","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SD Al Irsyad Kota Sorong","jenjang":"SD","kategori_responden":"Orang Tua","jumlah_populasi":166,"jumlah_blast":41,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1016","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SD Al Irsyad Kota Sorong","jenjang":"SD","kategori_responden":"Guru","jumlah_populasi":30,"jumlah_blast":30,"target_perolehan":6,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1016","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SD Al Irsyad Kota Sorong","jenjang":"SD","kategori_responden":"Pimpinan","jumlah_populasi":1,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1022","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIN Kota Bogor ","jenjang":"SD","kategori_responden":"Siswa","jumlah_populasi":34,"jumlah_blast":8,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1022","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIN Kota Bogor ","jenjang":"SD","kategori_responden":"Orang Tua","jumlah_populasi":34,"jumlah_blast":8,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1022","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIN Kota Bogor ","jenjang":"SD","kategori_responden":"Guru","jumlah_populasi":9,"jumlah_blast":9,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1022","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIN Kota Bogor ","jenjang":"SD","kategori_responden":"Pimpinan","jumlah_populasi":3,"jumlah_blast":3,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1026","101_provinsi":"Lampung","102_kab_kota":"Lampung Tengah","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIS Miftahul Huda","jenjang":"SD","kategori_responden":"Siswa","jumlah_populasi":29,"jumlah_blast":7,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1026","101_provinsi":"Lampung","102_kab_kota":"Lampung Tengah","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIS Miftahul Huda","jenjang":"SD","kategori_responden":"Orang Tua","jumlah_populasi":29,"jumlah_blast":7,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1026","101_provinsi":"Lampung","102_kab_kota":"Lampung Tengah","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIS Miftahul Huda","jenjang":"SD","kategori_responden":"Guru","jumlah_populasi":11,"jumlah_blast":11,"target_perolehan":3,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1026","101_provinsi":"Lampung","102_kab_kota":"Lampung Tengah","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIS Miftahul Huda","jenjang":"SD","kategori_responden":"Pimpinan","jumlah_populasi":2,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1036","101_provinsi":"Lampung","102_kab_kota":"Lampung","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"Universitas Muhammadiyah Lampung ","jenjang":"PT","kategori_responden":"Mahasiswa","jumlah_populasi":5008,"jumlah_blast":87,"target_perolehan":18,"Suffixurl":"pretest2-spipendidikanmahasiswa","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1036","101_provinsi":"Lampung","102_kab_kota":"Lampung","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"Universitas Muhammadiyah Lampung ","jenjang":"PT","kategori_responden":"Dosen","jumlah_populasi":34,"jumlah_blast":6,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikandosen","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1007","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN 2 Merak Belantung","jenjang":"SD","kategori_responden":"Siswa","jumlah_populasi":41,"jumlah_blast":10,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1007","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN 2 Merak Belantung","jenjang":"SD","kategori_responden":"Orang Tua","jumlah_populasi":41,"jumlah_blast":10,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1007","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN 2 Merak Belantung","jenjang":"SD","kategori_responden":"Guru","jumlah_populasi":14,"jumlah_blast":14,"target_perolehan":3,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1007","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SDN 2 Merak Belantung","jenjang":"SD","kategori_responden":"Pimpinan","jumlah_populasi":3,"jumlah_blast":3,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1025","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIN 6 Bandar Lampung ","jenjang":"SD","kategori_responden":"Siswa","jumlah_populasi":123,"jumlah_blast":30,"target_perolehan":6,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1025","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIN 6 Bandar Lampung ","jenjang":"SD","kategori_responden":"Orang Tua","jumlah_populasi":123,"jumlah_blast":30,"target_perolehan":6,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1025","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIN 6 Bandar Lampung ","jenjang":"SD","kategori_responden":"Guru","jumlah_populasi":37,"jumlah_blast":37,"target_perolehan":8,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1025","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIN 6 Bandar Lampung ","jenjang":"SD","kategori_responden":"Pimpinan","jumlah_populasi":3,"jumlah_blast":3,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1005","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMKN 4 Bogor","jenjang":"SMA","kategori_responden":"Siswa","jumlah_populasi":664,"jumlah_blast":46,"target_perolehan":10,"Suffixurl":"pretest2-spipendidikansma","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1005","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMKN 4 Bogor","jenjang":"SMA","kategori_responden":"Orang Tua","jumlah_populasi":664,"jumlah_blast":46,"target_perolehan":10,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1005","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMKN 4 Bogor","jenjang":"SMA","kategori_responden":"Guru","jumlah_populasi":74,"jumlah_blast":61,"target_perolehan":13,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1005","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMKN 4 Bogor","jenjang":"SMA","kategori_responden":"Pimpinan","jumlah_populasi":5,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1012","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMAN 5 Bandar Lampung","jenjang":"SMA","kategori_responden":"Siswa","jumlah_populasi":1123,"jumlah_blast":78,"target_perolehan":16,"Suffixurl":"pretest2-spipendidikansma","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1012","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMAN 5 Bandar Lampung","jenjang":"SMA","kategori_responden":"Orang Tua","jumlah_populasi":1014,"jumlah_blast":78,"target_perolehan":16,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1012","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMAN 5 Bandar Lampung","jenjang":"SMA","kategori_responden":"Guru","jumlah_populasi":64,"jumlah_blast":53,"target_perolehan":11,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1012","101_provinsi":"Lampung","102_kab_kota":"Bandar Lampung","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMAN 5 Bandar Lampung","jenjang":"SMA","kategori_responden":"Pimpinan","jumlah_populasi":5,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1019","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMA Negeri 4 Kota Sorong ","jenjang":"SMA","kategori_responden":"Siswa","jumlah_populasi":147,"jumlah_blast":10,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikansma","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1019","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMA Negeri 4 Kota Sorong ","jenjang":"SMA","kategori_responden":"Orang Tua","jumlah_populasi":147,"jumlah_blast":10,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1019","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMA Negeri 4 Kota Sorong ","jenjang":"SMA","kategori_responden":"Guru","jumlah_populasi":19,"jumlah_blast":16,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1019","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMA Negeri 4 Kota Sorong ","jenjang":"SMA","kategori_responden":"Pimpinan","jumlah_populasi":3,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1021","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMTK Luks Cornelia Kota Sorong ","jenjang":"SMA","kategori_responden":"Siswa","jumlah_populasi":600,"jumlah_blast":42,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikansma","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1021","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMTK Luks Cornelia Kota Sorong ","jenjang":"SMA","kategori_responden":"Orang Tua","jumlah_populasi":600,"jumlah_blast":42,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1021","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMTK Luks Cornelia Kota Sorong ","jenjang":"SMA","kategori_responden":"Guru","jumlah_populasi":50,"jumlah_blast":41,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1021","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMTK Luks Cornelia Kota Sorong ","jenjang":"SMA","kategori_responden":"Pimpinan","jumlah_populasi":5,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1029","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MAN 1 Lampung Selatan ","jenjang":"SMA","kategori_responden":"Siswa","jumlah_populasi":271,"jumlah_blast":19,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikansma","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1029","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MAN 1 Lampung Selatan ","jenjang":"SMA","kategori_responden":"Orang Tua","jumlah_populasi":169,"jumlah_blast":19,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1029","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MAN 1 Lampung Selatan ","jenjang":"SMA","kategori_responden":"Guru","jumlah_populasi":50,"jumlah_blast":41,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1029","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MAN 1 Lampung Selatan ","jenjang":"SMA","kategori_responden":"Pimpinan","jumlah_populasi":5,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1010","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMP PGRI 2 Katibung ","jenjang":"SMP","kategori_responden":"Siswa","jumlah_populasi":281,"jumlah_blast":11,"target_perolehan":3,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1010","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMP PGRI 2 Katibung ","jenjang":"SMP","kategori_responden":"Orang Tua","jumlah_populasi":281,"jumlah_blast":12,"target_perolehan":3,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1010","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMP PGRI 2 Katibung ","jenjang":"SMP","kategori_responden":"Guru","jumlah_populasi":19,"jumlah_blast":13,"target_perolehan":3,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1010","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMP PGRI 2 Katibung ","jenjang":"SMP","kategori_responden":"Pimpinan","jumlah_populasi":4,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1017","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMP Negeri 2 Kota Sorong ","jenjang":"SMP","kategori_responden":"Siswa","jumlah_populasi":586,"jumlah_blast":23,"target_perolehan":5,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1017","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMP Negeri 2 Kota Sorong ","jenjang":"SMP","kategori_responden":"Orang Tua","jumlah_populasi":551,"jumlah_blast":23,"target_perolehan":5,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1017","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMP Negeri 2 Kota Sorong ","jenjang":"SMP","kategori_responden":"Guru","jumlah_populasi":46,"jumlah_blast":32,"target_perolehan":7,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1017","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMP Negeri 2 Kota Sorong ","jenjang":"SMP","kategori_responden":"Pimpinan","jumlah_populasi":4,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1023","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MTs Darul Qur’an Cisarua ","jenjang":"SMP","kategori_responden":"Siswa","jumlah_populasi":457,"jumlah_blast":18,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1023","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MTs Darul Qur’an Cisarua ","jenjang":"SMP","kategori_responden":"Orang Tua","jumlah_populasi":457,"jumlah_blast":18,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1023","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MTs Darul Qur’an Cisarua ","jenjang":"SMP","kategori_responden":"Guru","jumlah_populasi":34,"jumlah_blast":24,"target_perolehan":5,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1023","101_provinsi":"Jawa Barat","102_kab_kota":"Bogor","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MTs Darul Qur’an Cisarua ","jenjang":"SMP","kategori_responden":"Pimpinan","jumlah_populasi":4,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1031","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"Mts Sains Algebra Kota Sorong","jenjang":"SMP","kategori_responden":"Siswa","jumlah_populasi":175,"jumlah_blast":7,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1031","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"Mts Sains Algebra Kota Sorong","jenjang":"SMP","kategori_responden":"Orang Tua","jumlah_populasi":175,"jumlah_blast":7,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1031","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"Mts Sains Algebra Kota Sorong","jenjang":"SMP","kategori_responden":"Guru","jumlah_populasi":14,"jumlah_blast":10,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1031","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"Mts Sains Algebra Kota Sorong","jenjang":"SMP","kategori_responden":"Pimpinan","jumlah_populasi":3,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1040","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"Politeknik Kesehatan Kementerian Kesehatan ","jenjang":"PT","kategori_responden":"Mahasiswa","jumlah_populasi":192,"jumlah_blast":3,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikanmahasiswa","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1040","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"Politeknik Kesehatan Kementerian Kesehatan ","jenjang":"PT","kategori_responden":"Pimpinan","jumlah_populasi":25,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikanrektor","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1040","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"Politeknik Kesehatan Kementerian Kesehatan ","jenjang":"PT","kategori_responden":"Dosen","jumlah_populasi":52,"jumlah_blast":10,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikandosen","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1015","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SD Negeri 26 Kota Sorong ","jenjang":"SD","kategori_responden":"Siswa","jumlah_populasi":100,"jumlah_blast":24,"target_perolehan":5,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1015","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SD Negeri 26 Kota Sorong ","jenjang":"SD","kategori_responden":"Orang Tua","jumlah_populasi":100,"jumlah_blast":24,"target_perolehan":5,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1015","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SD Negeri 26 Kota Sorong ","jenjang":"SD","kategori_responden":"Guru","jumlah_populasi":18,"jumlah_blast":18,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1015","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SD Negeri 26 Kota Sorong ","jenjang":"SD","kategori_responden":"Pimpinan","jumlah_populasi":1,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1032","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIN Sorong Kab. Sorong ","jenjang":"SD","kategori_responden":"Siswa","jumlah_populasi":83,"jumlah_blast":20,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1032","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIN Sorong Kab. Sorong ","jenjang":"SD","kategori_responden":"Orang Tua","jumlah_populasi":83,"jumlah_blast":20,"target_perolehan":4,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1032","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIN Sorong Kab. Sorong ","jenjang":"SD","kategori_responden":"Guru","jumlah_populasi":22,"jumlah_blast":22,"target_perolehan":5,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1032","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MIN Sorong Kab. Sorong ","jenjang":"SD","kategori_responden":"Pimpinan","jumlah_populasi":1,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1014","101_provinsi":"Lampung","102_kab_kota":"Lampung Tengah","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMAN 1 Terusan Nunyai","jenjang":"SMA","kategori_responden":"Siswa","jumlah_populasi":911,"jumlah_blast":64,"target_perolehan":13,"Suffixurl":"pretest2-spipendidikansma","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1014","101_provinsi":"Lampung","102_kab_kota":"Lampung Tengah","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMAN 1 Terusan Nunyai","jenjang":"SMA","kategori_responden":"Orang Tua","jumlah_populasi":911,"jumlah_blast":64,"target_perolehan":13,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1014","101_provinsi":"Lampung","102_kab_kota":"Lampung Tengah","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMAN 1 Terusan Nunyai","jenjang":"SMA","kategori_responden":"Guru","jumlah_populasi":53,"jumlah_blast":44,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1014","101_provinsi":"Lampung","102_kab_kota":"Lampung Tengah","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMAN 1 Terusan Nunyai","jenjang":"SMA","kategori_responden":"Pimpinan","jumlah_populasi":5,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1020","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMK YPK Imanuel Kota Sorong ","jenjang":"SMA","kategori_responden":"Siswa","jumlah_populasi":600,"jumlah_blast":42,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikansma","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1020","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMK YPK Imanuel Kota Sorong ","jenjang":"SMA","kategori_responden":"Orang Tua","jumlah_populasi":600,"jumlah_blast":42,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1020","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMK YPK Imanuel Kota Sorong ","jenjang":"SMA","kategori_responden":"Guru","jumlah_populasi":50,"jumlah_blast":41,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1020","101_provinsi":"Papua Barat Daya","102_kab_kota":"Kota Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMK YPK Imanuel Kota Sorong ","jenjang":"SMA","kategori_responden":"Pimpinan","jumlah_populasi":5,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1011","101_provinsi":"Lampung","102_kab_kota":"Lampung Tengah","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 1 Seputih Agung","jenjang":"SMP","kategori_responden":"Siswa","jumlah_populasi":706,"jumlah_blast":28,"target_perolehan":6,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1011","101_provinsi":"Lampung","102_kab_kota":"Lampung Tengah","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 1 Seputih Agung","jenjang":"SMP","kategori_responden":"Orang Tua","jumlah_populasi":706,"jumlah_blast":30,"target_perolehan":6,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1011","101_provinsi":"Lampung","102_kab_kota":"Lampung Tengah","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 1 Seputih Agung","jenjang":"SMP","kategori_responden":"Guru","jumlah_populasi":65,"jumlah_blast":45,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1011","101_provinsi":"Lampung","102_kab_kota":"Lampung Tengah","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 1 Seputih Agung","jenjang":"SMP","kategori_responden":"Pimpinan","jumlah_populasi":4,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1028","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MTs Raudlatul Jannah","jenjang":"SMP","kategori_responden":"Siswa","jumlah_populasi":1111,"jumlah_blast":44,"target_perolehan":9,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1028","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MTs Raudlatul Jannah","jenjang":"SMP","kategori_responden":"Orang Tua","jumlah_populasi":1111,"jumlah_blast":47,"target_perolehan":10,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1028","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MTs Raudlatul Jannah","jenjang":"SMP","kategori_responden":"Guru","jumlah_populasi":14,"jumlah_blast":10,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1028","101_provinsi":"Lampung","102_kab_kota":"Lampung Selatan","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MTs Raudlatul Jannah","jenjang":"SMP","kategori_responden":"Pimpinan","jumlah_populasi":4,"jumlah_blast":1,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Sumatera"},
{"npsn":"1018","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMP Muhamadiyah Al Amin Kota Sorong ","jenjang":"SMP","kategori_responden":"Siswa","jumlah_populasi":293,"jumlah_blast":12,"target_perolehan":3,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1018","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMP Muhamadiyah Al Amin Kota Sorong ","jenjang":"SMP","kategori_responden":"Orang Tua","jumlah_populasi":2,"jumlah_blast":0,"target_perolehan":0,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1018","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMP Muhamadiyah Al Amin Kota Sorong ","jenjang":"SMP","kategori_responden":"Guru","jumlah_populasi":22,"jumlah_blast":15,"target_perolehan":3,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1018","101_provinsi":"Papua Barat Daya","102_kab_kota":"Sorong","103_status_satuan_pendidikan":"Swasta","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMP Muhamadiyah Al Amin Kota Sorong ","jenjang":"SMP","kategori_responden":"Pimpinan","jumlah_populasi":25,"jumlah_blast":8,"target_perolehan":2,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Nusa Tenggara, Maluku, Papua"},
{"npsn":"1004","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 7 Bogor","jenjang":"SMP","kategori_responden":"Siswa","jumlah_populasi":644,"jumlah_blast":26,"target_perolehan":6,"Suffixurl":"pretest2-spipendidikansdsmp","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1004","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 7 Bogor","jenjang":"SMP","kategori_responden":"Orang Tua","jumlah_populasi":644,"jumlah_blast":26,"target_perolehan":6,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1004","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 7 Bogor","jenjang":"SMP","kategori_responden":"Guru","jumlah_populasi":34,"jumlah_blast":24,"target_perolehan":5,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1004","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMPN 7 Bogor","jenjang":"SMP","kategori_responden":"Pimpinan","jumlah_populasi":5,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1024","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MAN 2 Kota Bogor ","jenjang":"SMA","kategori_responden":"Siswa","jumlah_populasi":787,"jumlah_blast":55,"target_perolehan":11,"Suffixurl":"pretest2-spipendidikansma","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1024","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MAN 2 Kota Bogor ","jenjang":"SMA","kategori_responden":"Orang Tua","jumlah_populasi":787,"jumlah_blast":55,"target_perolehan":11,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1024","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MAN 2 Kota Bogor ","jenjang":"SMA","kategori_responden":"Guru","jumlah_populasi":67,"jumlah_blast":55,"target_perolehan":11,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1024","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"KEAGAMAAN","105_nama_satuan_pendidikan":"MAN 2 Kota Bogor ","jenjang":"SMA","kategori_responden":"Pimpinan","jumlah_populasi":6,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1006","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMAN 1 Bogor","jenjang":"SMA","kategori_responden":"Siswa","jumlah_populasi":763,"jumlah_blast":53,"target_perolehan":11,"Suffixurl":"pretest2-spipendidikansma","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1006","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMAN 1 Bogor","jenjang":"SMA","kategori_responden":"Orang Tua","jumlah_populasi":763,"jumlah_blast":53,"target_perolehan":11,"Suffixurl":"pretest2-spipendidikanortu","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1006","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMAN 1 Bogor","jenjang":"SMA","kategori_responden":"Guru","jumlah_populasi":60,"jumlah_blast":50,"target_perolehan":10,"Suffixurl":"pretest2-spipendidikanguru","ALL":"Semua","regional":"Jawa & LN"},
{"npsn":"1006","101_provinsi":"Jawa Barat","102_kab_kota":"Kota Bogor","103_status_satuan_pendidikan":"Negeri","104_jenis_satuan_pendidikan":"UMUM","105_nama_satuan_pendidikan":"SMAN 1 Bogor","jenjang":"SMA","kategori_responden":"Pimpinan","jumlah_populasi":5,"jumlah_blast":2,"target_perolehan":1,"Suffixurl":"pretest2-spipendidikankepsek","ALL":"Semua","regional":"Jawa & LN"}
]