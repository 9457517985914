import React, { Component } from 'react'
import Chart from 'react-google-charts'
import * as dataDashboard from './constData'



const groupedData = dataDashboard.dataDashboard.reduce((acc, entry) => {
    const { '101_provinsi': provinsi, jenjang, '105_nama_satuan_pendidikan': namaSatuan, target_perolehan } = entry;
  
    if (!acc[provinsi]) {
      acc[provinsi] = {
        total: 0,
        jenjangs: {
          SD: { count: 0, targetPerolehan: 0 },
          SMP: { count: 0, targetPerolehan: 0 },
          SMA: { count: 0, targetPerolehan: 0 },
          PT: { count: 0, targetPerolehan: 0 }
        }
      };
    }
  
    if (!acc[provinsi].jenjangs[jenjang].names) {
      acc[provinsi].jenjangs[jenjang].names = new Set();
    }
  
    acc[provinsi].jenjangs[jenjang].names.add(namaSatuan);
    acc[provinsi].jenjangs[jenjang].targetPerolehan += target_perolehan;
    acc[provinsi].jenjangs[jenjang].count += 1;
    acc[provinsi].total += target_perolehan;
  
    return acc;
  }, {});

  const geoChartData = [
    ['Subdivision name',  'total_target'],
    ...Object.entries(groupedData).map(([provinsi, provinsiData]) => {
      return [
        provinsi,
        provinsiData.total
      ];
    })
  ];
  
class GeoChart extends Component {
  render() {
    return (
      <div className="container mt-5">
        <Chart
          width={'700px'}
          height={'320px'}
          chartType="GeoChart"
          data={geoChartData}
          // Note: you will need to get a mapsApiKey for your project.
          // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
          mapsApiKey="YOUR_MAP_API_KEY_GOES_HERE"
        />
      </div>
    )
  }
}
export default GeoChart