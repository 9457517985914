import React, {Fragment} from 'react'
import {Route, Switch} from 'react-router-dom'
import './scss/style.scss';
import LandingPages from "./pages/landingpages/LandingPages";
import {routesPages} from "./routes";
import {CFade} from "@coreui/react";
import LandingpageIPLM from "./pages/landingpages/iplm/LandingpageIPLM";
import DashboardAPJII from './pages/dashboardpages/APJII/DashboardAPJII';
import DashboardPages from "./pages/dashboardpages/Dashboard.js"
import Login from "./pages/login/Login";
import {connect} from "react-redux";
import TheLayout from "./containers/TheLayout";
import storageActions from "./redux/actions/storageActions";
import propTypes from "prop-types";


const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

const Kuesioner = React.lazy(() => import('./pages/kuesioner/Kuesioner'));

const App = () => {

    return (
        < Fragment>
            {(() => {

                return <>
                    <React.Suspense fallback={loading}>
                        {/*khusus perpusnas*/}
                        {window.location.href.includes('/#/kuesioner/IPLM2023') ? window.location.href = '/pageIPLM' : ''}

                        <Switch>
                            {/*khusus perpusnas*/}
                            <Route path="/pageIPLM" name="Landing Page IPLM"
                                   render={props => <LandingpageIPLM {...props} />}/>

                            {/*khusus APJII*/}
                            <Route path="/pageAPJII" name="Hasil APJII"
                                   render={props => <DashboardAPJII {...props} />}/>

                            {routesPages.map((route, idx) => {
                                return route.component && (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        name={route.name}
                                        render={props => (
                                            <CFade>
                                                <route.component {...props} />
                                            </CFade>
                                        )}/>
                                )
                            })}
                            <Route path="/login" name="Login Page"
                                   render={props => <Login {...props} />}/>
                            <Route path="/landingpage" name="Landing Page"
                                   render={props => <LandingPages {...props} />}/>
                            <Route path="/dashboardpage" name="Dashboard Page"
                                   render={props => <DashboardPages {...props} />}/>
                            <Route path="/kuesioner/:slug" name="Kuesioner Page"
                                   render={props => <Kuesioner {...props} />}/>
                            <Route path="*" name="Home"
                                   render={props => <TheLayout {...props}/>}/>
                            {/*<Route path="*" render={() => (*/}
                            {/*    <Redirect to="/home"/>)}/>*/}
                        </Switch>
                    </React.Suspense>
                </>

            })()}
        </Fragment>
    )

}
const mapStateToProps = (state) => ({
    ...state.storageReducer,
});
const mapDisppatchToProps = {
    setAccessTokenResponse: storageActions.setAccessTokenResponse
};
App.propTypes = {
    setAccessTokenResponse: propTypes.func
};

export default connect(mapStateToProps, mapDisppatchToProps)(App);
