import React, {useEffect, useState} from 'react'

import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow
} from '@coreui/react'
import propTypes from "prop-types";
import {connect} from "react-redux";
import storageActions from "../../redux/actions/storageActions";
import {useHistory} from "react-router-dom";
import {FaLock, FaUser} from "react-icons/fa";

const Login = ({getAccessTokenResponse, isAuthenticated}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({
        username: "",
        password: ""
    });

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/home')
        }
    })

    const history = useHistory();
    //Handle perubahan pada input + cek validasi input
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        if (name === 'username') {
            setUsername(value)
        } else if (name === 'password') {
            setPassword(value)
        }
    }

    const handleSubmit = async (e) => {
        const input = {
            username: username,
            password: password
        }
        let isValid = true;
        Object.keys(input).forEach((val) => {

            if (input[val] < 1) {
                setErrors(prevState => ({
                    ...prevState,
                    [val]: "Wajib di isi"
                }))
                isValid = false
            } else {
                setErrors(prevState => ({
                    ...prevState,
                    [val]: ""
                }))
            }
        });

        if (isValid){
            getAccessTokenResponse(input)


            // history.push('/home')
        }


    };

    const onKeyPress = (e) => {
        if (e.which === 13) {
            handleSubmit();
        }
    }
    return (

        <div className="c-app c-default-layout flex-row align-items-center"
             style={{backgroundImage: "url(logo/Blank.jpeg)"}}>
            <CContainer>
                {/*<CRow className="justify-content-left">*/}
                {/*  { <CImg*/}
                {/*    className="c-sidebar-brand-full"*/}
                {/*    src={'logo/logo-wesurvey.png'}*/}
                {/*    height={90}*/}
                {/*  />}*/}
                {/*</CRow>*/}
                <CRow className="justify-content-center">
                    <CCol md="8">
                        <h1 className="text-center">Selamat Datang</h1>
                        <br/>
                        {/*<h2 className="text-center">Penilaian Indeks Efektivitas APU PPT</h2>*/}
                        {/*<h2 className="text-center">Tahun 2021</h2>*/}
                    </CCol>
                </CRow>
                <CRow className="justify-content-center">
                    <CCol md="8">
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm>
                                        <h1>Login</h1>
                                        <p className="text-muted">Sign In to your account</p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <FaUser/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                                invalid={errors.username.length > 0}
                                                id="username"
                                                name="username"
                                                placeholder="Username"
                                                onChange={handleInputChange}
                                                value={username}
                                                onKeyDown={onKeyPress}
                                            />
                                        </CInputGroup>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <FaLock/>
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput
                                                invalid={errors.password.length > 0}
                                                type="password"
                                                id="password"
                                                name="password"
                                                autoComplete="new-password"
                                                placeholder="Password"
                                                onChange={handleInputChange}
                                                value={password}
                                                onKeyDown={onKeyPress}
                                            />
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs="6">
                                                <CButton color="primary" className="px-4"
                                                         onClick={handleSubmit}>Login</CButton>
                                            </CCol>
                                            {/* <CCol xs="6" className="text-right">
                        <CButton color="link" className="px-0">Forgot password?</CButton>
                      </CCol> */}
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}


const mapStateToProps = (state) => ({
    ...state.storageReducer,
});
const mapDisppatchToProps = {
    getAccessTokenResponse: storageActions.getAccessTokenResponse
};
Login.propTypes = {
    getAccessTokenResponse: propTypes.func
};

export default connect(mapStateToProps, mapDisppatchToProps)(Login);
