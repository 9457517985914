import React, {useEffect, useState} from 'react'
import {Bar} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import axios from "axios";
import {SERVICE} from "../../../lib/config";
import Swal from "sweetalert2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

let buildDataSets = (datas) => {
    const labels = Object.values(datas).map(item => item["group"]);
    return {
        labels: labels,
        datasets: [
            {
                label: 'Jumlah Data Populasi',
                backgroundColor: 'rgba(54, 162, 235, 1)',
                borderColor: 'rgba(54,162,235,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(54,162,235,0.4)',
                hoverBorderColor: 'rgba(54,162,235,1)',
                data: Object.values(datas).map(item => item["total_populasi"]),
            },
            {
                label: 'Jumlah Blast',
                backgroundColor: 'rgba(255,99,132,1)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
                data: Object.values(datas).map(item => item["total_blast"]),
            },
            {
                label: 'Target Perolehan',
                backgroundColor: 'rgba(75, 192, 192, 1)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(75, 192, 192, 0.4)',
                hoverBorderColor: 'rgba(75, 192, 192, 1)',
                data: Object.values(datas).map(item => item["total_target_perolehan"]),
            },
            {
                label: 'Jumlah Perolehan',
                backgroundColor: 'rgba(153, 102, 255, 1)',
                borderColor: 'rgba(153, 102, 255, 1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(153, 102, 255, 0.4)',
                hoverBorderColor: 'rgba(153, 102, 255, 1)',
                data: Object.values(datas).map(item => item["total_jumlah_perolehan"]),
            },
        ],
    }
}


const GrafikSPI = () => {
    const [barNational, setBarNational] = useState({
        labels: [],
        datasets: [],
    });
    const [barKategori, setBarKategori] = useState({
        labels: [],
        datasets: [],
    });
    const [barJenjang, setBarJenjang] = useState({
        labels: [],
        datasets: [],
    });
    const [barRegional, setBarRegional] = useState({
        labels: [],
        datasets: [],
    });

    useEffect(() => {
        const uninterceptedAxiosInstance = axios.create();
        let inputNasional = {
            "surveyId": "25",
            "suffixRestData": "dashboardPretest2",
            "groupRekap": "ALL"
        }

        uninterceptedAxiosInstance.post(SERVICE.JAVA_SERVICE + "/dashboardpage/spi/rekap", inputNasional)
            .then((response) => {
                if (response.data.status === 200) {
                    setBarNational(buildDataSets(response.data.data))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: response.data.message
                    })
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Jaringan Buruk'
                })
            })

        let inputKategori = {
            "surveyId": "25",
            "suffixRestData": "dashboardPretest2",
            "groupRekap": "kategori_responden"
        }

        uninterceptedAxiosInstance.post(SERVICE.JAVA_SERVICE + "/dashboardpage/spi/rekap", inputKategori)
            .then((response) => {
                if (response.data.status === 200) {
                    setBarKategori(buildDataSets(response.data.data))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: response.data.message
                    })
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Jaringan Buruk'
                })
            })

        let inputJenjang = {
            "surveyId": "25",
            "suffixRestData": "dashboardPretest2",
            "groupRekap": "jenjang"
        }

        uninterceptedAxiosInstance.post(SERVICE.JAVA_SERVICE + "/dashboardpage/spi/rekap", inputJenjang)
            .then((response) => {
                if (response.data.status === 200) {
                    setBarJenjang(buildDataSets(response.data.data))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: response.data.message
                    })
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Jaringan Buruk'
                })
            })

        let inputRegional = {
            "surveyId": "25",
            "suffixRestData": "dashboardPretest2",
            "groupRekap": "regional"
        }

        uninterceptedAxiosInstance.post(SERVICE.JAVA_SERVICE + "/dashboardpage/spi/rekap", inputRegional)
            .then((response) => {
                if (response.data.status === 200) {
                    setBarRegional(buildDataSets(response.data.data))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: response.data.message
                    })
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Jaringan Buruk'
                })
            })


    }, [])

    const yScaleLogarithmic = {
        type: 'logarithmic', 
        title: {
            display: true,
        },
    };

    const chartOptions = {
        scales: {
            y: yScaleLogarithmic, 
        },
    };

    return (

        <div className="container">
            <h3><strong>Grafik Perolehan</strong></h3>

            <h4>Perolehan Nasional</h4>
            <div className="chart-wrapper" style={{marginBottom: 30, marginTop: 20}}>
                <Bar data={barNational} options={chartOptions} />
            </div>

            <h4>Perolehan Per Kategori</h4>
            <div className="chart-wrapper" style={{marginBottom: 30}}>
                <Bar data={barKategori} options={chartOptions}/>
            </div>
            <h4>Perolehan Per Jenjang</h4>
            <div className="chart-wrapper" style={{marginBottom: 30}}>
                <Bar data={barJenjang} options={chartOptions}/>
            </div>

            <h4>Perolehan Per Regional</h4>
            <div className="chart-wrapper" style={{marginBottom: 30}}>
                <Bar data={barRegional} options={chartOptions}/>
            </div>


        </div>

    )

}

export default GrafikSPI