import { combineReducers } from 'redux'

import sidebarReducer from './sidebar'
import dataviewReducer from './dataview'
import storageReducer from "./storageReducer";

const rootReducer = combineReducers({
    sidebarReducer,
    dataviewReducer,
    storageReducer
})
export default rootReducer
