import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import Swal from 'sweetalert2';
import { SERVICE } from '../../../lib/config';

const AggregatedTable = ({ input }) => {
  const [tableData, setTableData] = useState([
    {
      tanggal: '',
      capaian: 0,
    },
  ]);

   // Define a mapping function to map numeric values to labels
   const mapLabel = (value) => {
    const labelMap = {
      1: 'Energi',
      2: 'Barang baku',
      3: 'Perindustrian',
      4: 'Barang konsumen primer',
      5: 'Barang konsumen non primer',
      6: 'Kesehatan',
      7: 'Keuangan',
      8: 'Properti & real estat',
      9: 'Teknologi',
      10: 'Infrastruktur',
      11: 'Transportasi dan logistik',
    };

    return labelMap[value] || value;
  };

  useEffect(() => {
    const uninterceptedAxiosInstance = axios.create();

    // Get the token from wherever you store it (e.g., localStorage, Redux store, etc.)
    const token = "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJRUE8xWWNSelBiMVRCNkNWMjBFWFVyZ0dRenczOVdOZmV0VEpnZFlpcjNVIn0.eyJleHAiOjE3MDYyMzYwNDUsImlhdCI6MTcwNjE0OTY0NSwianRpIjoiY2QyM2I2NzMtZGVmNi00YmNlLWE4ZjktNDE1NDdlYzE4MjNlIiwiaXNzIjoiaHR0cHM6Ly9rdWthbmcudGVub3MuaWQvYXV0aC9yZWFsbXMvd2VzdXJ2ZXktcmVhbG0iLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiNGJmNmMzMmItNDQxZS00MmRkLWE0NWMtMTczZTA1MmQ0MzRiIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoid2VzdXJ2ZXktY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6IjMwNzdlNjM2LWI1MDktNDEyMy1hMmFmLTAwZDE2MmNlOWI3MiIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOlsiaHR0cHM6Ly9rdWthbmcudGVub3MuaWQiXSwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbInJvbGVfYWRtaW4iLCJkZWZhdWx0LXJvbGVzLXdlc3VydmV5LXJlYWxtIiwib2ZmbGluZV9hY2Nlc3MiLCJyb2xlX3N1cGVyYWRtaW4iLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7Indlc3VydmV5LWNsaWVudCI6eyJyb2xlcyI6WyJhY2Nlc3NfbWFzdGVyc3VydmV5IiwiYWNjZXNzX2Rvd25sb2FkIiwiYWNjZXNzX2RhdGFfdmlldyIsImFjY2Vzc19hZG1pbiIsImFjY2Vzc19kYXRhIiwiYWNjZXNzX2RhdGFfZGVsZXRlIiwiYWNjZXNzX3Jlc3RkYXRhIiwiYWNjZXNzX3F1aWNrYWNjZXNzIiwiYWNjZXNzX2Rhc2hib2FyZCIsImFjY2Vzc19kYXRhX2VkaXQiLCJhY2Nlc3Nfc2V0dGluZyIsImFjY2Vzc19zdXBlcmFkbWluIiwiYWNjZXNzX2lucHV0Il19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJzaWQiOiIzMDc3ZTYzNi1iNTA5LTQxMjMtYTJhZi0wMGQxNjJjZTliNzIiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsIm5hbWUiOiJzdXBlcmFkbWluIHN1cGVyYWRtaW4iLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJzdXBlcmFkbWluIiwiZ2l2ZW5fbmFtZSI6InN1cGVyYWRtaW4iLCJmYW1pbHlfbmFtZSI6InN1cGVyYWRtaW4iLCJlbWFpbCI6InN1cGVyYWRtaW5AdGVzdC5jb20ifQ.i8uPGU4xbMy7GZkUe_60daIuKbeJJ9hiApFRkT9aywyk5uI0XdF4TMZs5pUhibVIcc3lbxJnZ8pA_XWO3sEYQ735amwOlv9s6UWmUKQVJyCPb88aNEnyIQGKAdRtmHfHajrXiwE6VnvT4tecFsrdhvdGIWk2clXBzApGt9LoVCBOFeSs8q9mAfXzteukQTQmj79i9jOXUSacI7uYlj_PNTedVhmiboEdIBrIG7GqIwJgMKX8rNIwMX-eAlDrPcLDKH49A7sHu2udo8j5Qf_p8t3BzuZOWy_hzdnp--0pjnAUSpvTQvjdjB20MBH0sEx68WO-gBHMFLUgi06OAKd3rA";


    // Set the Authorization header with the bearer token
    uninterceptedAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    uninterceptedAxiosInstance
      .post(SERVICE.JAVA_SERVICE + '/dashboard/getTrenCapaian/column', input)
      .then((response) => {
        if (response.data.status === 200) {
          setTableData(response.data.data);
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data.message,
          });
        }
      })
      .catch((reason) => {
        Swal.fire({
          icon: 'error',
          title: reason,
        });
      });
  }, [input]);

  // Define table columns
  const tableColumns = [
    { selector: (row) => mapLabel(row.label), name: 'Sektor' },
    { selector: (row) => row.capaian, name: 'Jumlah Capaian' },
  ];

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <DataTable columns={tableColumns} data={tableData} pagination />
        </div>
      </div>
    </div>
  );
};

export default AggregatedTable;



     