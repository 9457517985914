import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import allReducer from './reducers'

const initialState = {}
const middleware = [thunk]

const rootReducer = (state, action) => {
  if (action.type === 'logout') {
    state = undefined
  }
  return allReducer(state, action)
}

const configureStore = () => {
  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware))
  )
}

export default configureStore
