import React from 'react'
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink
} from '@coreui/react'
import { connect } from "react-redux";
import propTypes from "prop-types";
import { sidebarActions } from '../redux/actions'

const TheHeader = ({ sidebarShow, onChange, access }) => {

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    onChange(val)
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    onChange(val)
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        {/* <CImg
          className="c-sidebar-brand-full"
          src={'logo/Logo_PPATK.png'}
          height={45}
        /> */}
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/home">Home</CHeaderNavLink>
        </CHeaderNavItem>
        {access.includes('_dashboard') &&
          <CHeaderNavItem className="px-3">
            <CHeaderNavLink to="/dashboard/wilayah">Dashboard</CHeaderNavLink>
          </CHeaderNavItem>}
      </CHeaderNav>

      {/* <CHeaderNav className="px-3">
        <TheHeaderDropdownNotif/>
        <TheHeaderDropdownTasks/>
        <TheHeaderDropdownMssg/>
        <TheHeaderDropdown/>
      </CHeaderNav> */}

      {/* <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routes}
        />
          <div className="d-md-down-none mfe-2 c-subheader-nav">
            <CLink className="c-subheader-nav-link"href="#">
              <CIcon name="cil-speech" alt="Settings" />
            </CLink>
            <CLink
              className="c-subheader-nav-link"
              aria-current="page"
              to="/dashboard"
            >
              <CIcon name="cil-graph" alt="Dashboard" />&nbsp;Dashboard
            </CLink>
            <CLink className="c-subheader-nav-link" href="#">
              <CIcon name="cil-settings" alt="Settings" />&nbsp;Settings
            </CLink>
          </div>
      </CSubheader> */}
    </CHeader>
  )
}
const mapStateToProps = (state) => ({
  ...state.sidebarReducer, ...state.storageReducer
});
const mapDisppatchToProps = {
  onChange: sidebarActions.onChange
};
TheHeader.propTypes = {
  onChange: propTypes.func
};

export default connect(mapStateToProps, mapDisppatchToProps)(TheHeader);
