import storageTypes from "../types/storageTypes";
import jwt from "jwt-decode";
import secureLocalStorage from "react-secure-storage";

const localAccessTokenResponse = secureLocalStorage.getItem('atr') ? JSON.parse(secureLocalStorage.getItem('atr')):''
const localAcessToken = localAccessTokenResponse ? localAccessTokenResponse.access_token : ''
const localUserInfo = localAcessToken ? jwt(localAcessToken) : ''


const initialState = {
    accessTokenResponse: localAccessTokenResponse ? localAccessTokenResponse : {},
    isAuthenticated: localAccessTokenResponse ? true : false,
    access: localUserInfo ? localUserInfo.resource_access['wesurvey-client'].roles.toString() : {},
    userInfo: localUserInfo ? localUserInfo : {},
    roles: localUserInfo ? localUserInfo.realm_access.roles : {},
    refreshToken: localAccessTokenResponse ? localAccessTokenResponse.refresh_token : '',
    username: localUserInfo ? localUserInfo.preferred_username : ''
}

const alsusReducer = (state = {...initialState}, action) => {
    switch (action.type) {
        case storageTypes.GET_ACCESS_TOKEN_RESPONSE: {
            const {data, isAuthenticated} = action.payload
            const accessToken = data.access_token
            const userInfo = jwt(accessToken)
            secureLocalStorage.setItem('atr', JSON.stringify(data))
            return {
                ...state,
                accessTokenResponse: data,
                isAuthenticated: isAuthenticated,
                userInfo: JSON.stringify(userInfo),
                roles: userInfo.realm_access.roles,
                access: userInfo.resource_access['wesurvey-client'].roles.toString(),
                refreshToken: data.refresh_token,
                username: userInfo.preferred_username
            }
        }
        case storageTypes.SET_ACCESS_TOKEN_RESPONSE: {
            const {data, isAuthenticated} = action.payload

            const accessToken = data.access_token
            const userInfo = jwt(accessToken)
            return {
                ...state,
                accessTokenResponse: data,
                isAuthenticated: isAuthenticated,
                userInfo: JSON.stringify(userInfo),
                roles: userInfo.realm_access.roles,
                access: userInfo.resource_access['wesurvey-client'].roles.toString(),
                refreshToken: data.refresh_token,
                username: userInfo.preferred_username
            }
        }
        case storageTypes.DO_LOGOUT: {
            secureLocalStorage.removeItem('atr')
            return {
                ...state,
                accessTokenResponse: '',
                isAuthenticated: '',
                userInfo: '',
                roles: '',
                access: '',
                refreshToken: '',
                username: ''
            }
        }
        default:
            return state
    }
}
export default alsusReducer
