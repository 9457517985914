import React, {useEffect, useState, useCallback} from 'react';
import DataTable from 'react-data-table-component';
import axios from "axios";
import {SERVICE} from "../../../lib/config";
import Swal from "sweetalert2";
import { CButton, CInput, CSelect } from '@coreui/react';

const CreateGroupedTable = () => {
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [npsnSearchQuery, setNpsnSearchQuery] = useState('');
    const [tableData, setTableData] = useState([])
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [detailTableData, setDetailTableData] = useState([])
    const [selectedJenjang, setSelectedJenjang] = useState('');
    const [selectedProvinsi, setSelectedProvinsi] = useState('');
    const [filteredJenjang, setFilteredJenjang] = useState('');
    const [filteredProvinsi, setFilteredProvinsi] = useState('');

    useEffect(() => {
        const uninterceptedAxiosInstance = axios.create();
        let input = {
            "surveyId": "33",
            "suffixRestData": "dashboardSPIPendidikan",
        }

        uninterceptedAxiosInstance.post(SERVICE.JAVA_SERVICE + "/dashboardpage/spi/getTableRekap", input)
            .then((response) => {
                if (response.data.status === 200) {

                    setTableData(response.data.data)
                    setFilteredTableData(response.data.data);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: response.data.message
                    })
                }
            })
            .catch(reason => {
                Swal.fire({
                    icon: 'error',
                    title: reason
                })
            })

    }, [])

    const handleResetFilter = useCallback(() => {
        setNpsnSearchQuery('');
        setFilteredTableData(tableData);
    }, [tableData]);

    const handleNpsnSearch = useCallback(() => {
        const filteredData = tableData.filter(item =>
            item.npsn.toLowerCase().includes(npsnSearchQuery.toLowerCase())
        );
    
        let combinedFilteredData = filteredData;
    
        if (filteredJenjang) {
            combinedFilteredData = combinedFilteredData.filter(item => item.jenjang === filteredJenjang);
        }
    
        if (filteredProvinsi) {
            combinedFilteredData = combinedFilteredData.filter(item => item.provinsi === filteredProvinsi);
        }
    
        setFilteredTableData(combinedFilteredData);
    }, [npsnSearchQuery, filteredJenjang, filteredProvinsi, tableData]);

    const handleJenjangFilter = useCallback(value => {
        setSelectedJenjang(value);
        const filteredData = tableData.filter(item => item.jenjang === value);
        setFilteredTableData(filteredData);
    }, [tableData]);

    const handleProvinsiFilter = useCallback(value => {
        setSelectedProvinsi(value);
        const filteredData = tableData.filter(item => item.provinsi === value);
        setFilteredTableData(filteredData);
    }, [tableData]);


    const handleGroupClick = groupName => {
        const uninterceptedAxiosInstance = axios.create();
        let inputKategori = {
            "surveyId": "33",
            "suffixRestData": "dashboardSPIPendidikan",
            "groupRekap": "kategori_responden",
            "filter":{"105_nama_satuan_pendidikan":[groupName]},
        }

        uninterceptedAxiosInstance.post(SERVICE.JAVA_SERVICE + "/dashboardpage/spi/rekap", inputKategori)
            .then((response) => {
                if (response.data.status === 200) {
                    setSelectedGroup(groupName)
                    setDetailTableData(response.data.data)
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: response.data.message
                    })
                }
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Jaringan Buruk'
                })
            })

    };

    // Define table columns
    const tableColumns = [
            {
                name: 'Aksi',
                cell: (row) => (
                    <button
                        style={{
                            backgroundColor: 'orange',
                            color: 'white',
                            border: 'none',
                            padding: '5px 10px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        onClick={() => handleGroupClick(row['nama_satuan_pendidikan'])}
                    >
                        Detail
                    </button>
                ),
                width: '90px',
            },
            {
                name: 'NPSN',
                selector: (row) => row.npsn,
                sortable: true,
                filter: (
                    <input
                        type="text"
                        placeholder="Cari NPSN"
                        value={npsnSearchQuery}
                        onChange={(e) => setNpsnSearchQuery(e.target.value)}
                    />
                ),
                wrap: true,
                width: '90px',
            },
            {
                name: 'Provinsi',
                selector: (row) => row.provinsi,
                sortable: true,
                wrap: true,
                width: '12%',
                filter: (
                    <select
                        value={selectedProvinsi}
                        onChange={(e) => handleProvinsiFilter(e.target.value)}
                    >
                        <option value="">All</option>
                        {Array.from(new Set(tableData.map((item) => item['provinsi']))).map(
                            (provinsi) => (
                                <option key={provinsi} value={provinsi}>
                                    {provinsi}
                                </option>
                            )
                        )}
                    </select>
                ),
            },
            {
                name: 'Nama Satdik',
                selector: (row) => row.nama_satuan_pendidikan,
                sortable: true,
                wrap: true,
                width: '17%',
            },
            {
                name: 'Jenjang',
                selector: (row) => row.jenjang,
                sortable: true,
                wrap: true,
                width: '9%',
                filter: (
                    <select
                        value={selectedJenjang}
                        onChange={(e) => handleJenjangFilter(e.target.value)}
                    >
                        <option value="">All</option>
                        {Array.from(new Set(tableData.map((item) => item.jenjang))).map(
                            (jenjang) => (
                                <option key={jenjang} value={jenjang}>
                                    {jenjang}
                                </option>
                            )
                        )}
                    </select>
                ),
            },
            {
                name: 'Populasi',
                selector: (row) => row.jumlah_populasi,
                sortable: true,
                wrap: true,
                width: '9%',
                right: true,
                cell: (row) => row.jumlah_populasi.toLocaleString(),
            },
            {
                name: 'Blast',
                selector: (row) => row.jumlah_blast,
                sortable: true,
                wrap: true,
                width: '8%',
                right: true,
                cell: (row) => row.jumlah_blast.toLocaleString(),
            },
            {
                name: 'Target',
                selector: (row) => row.target_perolehan,
                sortable: true,
                wrap: true,
                width: '8%',
                right: true,
                cell: (row) => row.target_perolehan.toLocaleString(),
            },
            {
                name: 'Perolehan',
                selector: (row) => row.total_jumlah_perolehan,
                sortable: true,
                wrap: true,
                width: '10%',
                right: true,
                cell: (row) => row.total_jumlah_perolehan.toLocaleString(),
            },
            {
                name: '%',
                selector: (row) => row.ketercapaian,
                sortable: true,
                wrap: true,
                width: '8%',
                right: true,
            },
        ];  

    const subHeaderComponentMemo = React.useMemo(() => {
        return (
            <div style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                <CSelect
                    value={filteredJenjang}
                    onChange={e => setFilteredJenjang(e.target.value)}
                    style={{ marginRight: '10px' }}
                >
                    <option value="">All Jenjang</option>
                    {Array.from(new Set(tableData.map(item => item.jenjang))).map(jenjang => (
                        <option key={jenjang} value={jenjang}>
                            {jenjang}
                        </option>
                    ))}
                </CSelect>
                <CSelect
                    value={filteredProvinsi}
                    onChange={e => setFilteredProvinsi(e.target.value)}
                    style={{ marginRight: '10px' }}
                >
                    <option value="">All Provinsi</option>
                    {Array.from(new Set(tableData.map(item => item['provinsi']))).map(
                        provinsi => (
                            <option key={provinsi} value={provinsi}>
                                {provinsi}
                            </option>
                        )
                    )}
                </CSelect>
                <CInput
                    type="text"
                    placeholder="Search NPSN"
                    value={npsnSearchQuery}
                    onChange={e => setNpsnSearchQuery(e.target.value)}
                    style={{ marginRight: '10px' }}
                />
                <CButton
                    style={{ backgroundColor: 'blue', color: 'white', marginRight: '10px' }}
                    onClick={handleNpsnSearch}
                >
                    Cari
                </CButton>
                <CButton
                    style={{ backgroundColor: 'red', color: 'white' }}
                    onClick={handleResetFilter}
                >
                    X
                </CButton>
            </div>
        );
    }, [npsnSearchQuery, filteredJenjang, filteredProvinsi, handleNpsnSearch, handleResetFilter, tableData]);
    

    return (
        <div style={{fontSize: '12px'}}>
            <DataTable
                columns={tableColumns}
                data={filteredTableData}
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 20, 30]}
                paginationComponentOptions={{rowsPerPageText: 'Baris per Halaman:'}}
                highlightOnHover
                pointerOnHover
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                subHeaderWrap
            />

            {selectedGroup && (
                <div>
                    <h3>Detail untuk {selectedGroup}</h3>
                    <DataTable
                      columns={[
                        { name: 'Kategori', selector: 'group', sortable: true },
                        { name: 'Jumlah Populasi', selector: 'total_populasi', sortable: true },
                        { name: 'Jumlah Blast', selector: 'total_blast', sortable: true },
                        { name: 'Target', selector: 'total_target_perolehan', sortable: true },
                        { name: 'Jumlah Perolehan', selector: 'total_jumlah_perolehan', sortable: true },
                        { name: '%Ketercapaian', selector: 'ketercapaian', sortable: true}
                      ]}
                      data={detailTableData}
                      pagination
                      paginationPerPage={5}
                      paginationRowsPerPageOptions={[5, 10, 20, 30]}
                      paginationComponentOptions={{ rowsPerPageText: 'Jumlah Data per Halaman:' }}
                      highlightOnHover
                      pointerOnHover
                    />
                </div>
            )}
        </div>
    );
};

export default CreateGroupedTable;
