import { dataviewTypes } from '../types';
import { SERVICE } from '../../lib/config';
import axios from 'axios';
import { LoadIndicator } from '../../lib/loadindicator'
import Swal from 'sweetalert2';

const dataviewActions = {
    getDataView: (input) => async (dispatch) => {
        try {
            LoadIndicator('Mengambil data...')
            const dataTable = (await axios.post(SERVICE.JAVA_SERVICE + '/mainSurvey/pagedData', input)).data
            if (dataTable.status === 200) {
                let fieldBase = [
                    'No.',
                    {
                        label: 'Created By',
                        key: 'createdBy'
                    },
                    {
                        label: 'Created On',
                        key: 'createdOn'
                    },
                    {
                        label: 'Finish On',
                        key: 'finishOn'
                    },
                    {
                        label: 'Modified By',
                        key: 'modifiedBy'
                    },{
                        label: 'Modified On',
                        key: 'modifiedOn'
                    },
                    'Action',
                ]
                for (var i = 0; i < dataTable.data.keyAndLabels?.length; i++) {
                    fieldBase.splice(i + 1, 0, dataTable.data.keyAndLabels[i])
                    fieldBase.join()
                }
                const payload = { data: dataTable, fieldDinamik: fieldBase }
                dispatch({ type: dataviewTypes.GET_DATA_VIEW, payload })
                Swal.close()
                return payload
            }
        } catch (e) {
            const payload = { data: [] };
            dispatch({ type: dataviewTypes.GET_DATA_VIEW, payload });
            return payload;
        }
    },
    onChange: ({ field, value }) => (dispatch) => {
        const payload = { field, value };
        dispatch({ type: dataviewTypes.CHANGE_INPUT_DATA_VIEW, payload });
    },
    onReset: ({ field, value }) => (dispatch) => {
        const payload = { field, value };
        dispatch({ type: dataviewTypes.ON_RESET_DATA, payload });
    },
    setModal: () => (dispatch) => {
        dispatch({ type: dataviewTypes.OPEN_MODAL_VIEW });
    },

};
export default dataviewActions;
