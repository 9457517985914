import React from 'react'
import {useDispatch, connect} from 'react-redux'
import {
    CCreateElement,
    CSidebar,
    CSidebarBrand,
    CSidebarNav,
    CSidebarNavDivider,
    CSidebarNavTitle,
    CSidebarMinimizer,
    CSidebarNavDropdown,
    CSidebarNavItem
} from '@coreui/react'

import propTypes from "prop-types";
import {sidebarActions, storageActions} from '../redux/actions'

const TheSidebar = ({sidebarShow, onChange, access, doLogout}) => {
    const dispatch = useDispatch()

    const show = sidebarShow
    const logout = () => {
        doLogout()
    }

    const nav = [
        {
            _tag: 'CSidebarNavTitle',
            _children: ['Wesurvey']
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Home',
            to: '/home',
            icon: 'cil-home',
        },
        access.includes('_dashboard') ?
            {
                _tag: 'CSidebarNavDropdown',
                name: 'Dashboard',
                icon: 'cil-institution',
                _children: [
                    // {
                    //     _tag: 'CSidebarNavItem',
                    //     name: 'Tren Capaian Kuesioner',
                    //     to: '/dashboard/kuesioner'
                    // },
                    // {
                    //   _tag: 'CSidebarNavItem',
                    //   name: 'Capaian Wilayah',
                    //   to: '/dashboard/wilayah'
                    // },
                    {
                        _tag: 'CSidebarNavItem',
                        name: 'Capaian User',
                        to: '/dashboard/enumerator'
                    },
                ],
            } : {
                _tag: 'CSidebarNavDivider'
            },
        {
            _tag: 'CSidebarNavTitle',
            _children: ['Menu']
        },
        access.includes('_input') ?
            {
                _tag: 'CSidebarNavItem',
                name: 'Isi Kuesioner',
                to: '/isisurvey/',
                icon: 'cil-plus',
            } : {
                _tag: 'CSidebarNavDivider'
            },
        access.includes('_data') ?
            {
                _tag: 'CSidebarNavItem',
                name: 'Data Kuesioner',
                to: '/dataview/',
                icon: 'cil-cursor',
            } : {
                _tag: 'CSidebarNavDivider'
            },

        access.includes('_setting') ?
            {
                _tag: 'CSidebarNavDropdown',
                name: 'Master User',
                route: '/base',
                icon: 'cil-settings',
                _children: [
                    {
                        _tag: 'CSidebarNavItem',
                        name: 'Import Excel File User',
                        to: '/settings/importexcel',
                    },
                    {
                        _tag: 'CSidebarNavItem',
                        name: 'Tambah User',
                        to: '/masterdata/user',
                    },
                    {
                        _tag: 'CSidebarNavItem',
                        name: 'Daftar User',
                        to: '/settings/userlist',
                    },
                    {
                        _tag: 'CSidebarNavItem',
                        name: 'Daftar User Dihapus',
                        to: '/settings/deleteduserlist',
                    }
                ],
            } : {
                _tag: 'CSidebarNavDivider'
            },

        access.includes('_mastersurvey') ?
            {
                _tag: 'CSidebarNavDropdown',
                name: 'Master Survey',
                // route: '/base',
                icon: 'cil-plus',
                _children: [
                    access.includes('_superadmin') ?
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Project Owner',
                            to: '/masterdata/project',
                        } : '',
                    access.includes('_admin') ?
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Survey',
                            to: '/masterdata/survey',
                        } : '',
                    // {
                    //   _tag: 'CSidebarNavItem',
                    //   name: 'Register',
                    //   // to: '/settings/adduser',
                    // },
                    access.includes('_superadmin') ?
                        {
                            _tag: 'CSidebarNavItem',
                            name: 'Role Survey',
                            to: '/masterdata/role',
                        } : ''
                ],
            } : {
                _tag: 'CSidebarNavDivider'
            },
        // {
        //   _tag: 'CSidebarNavItem',
        //   name: 'Petunjuk Teknis',
        //   icon: 'cil-book',
        //   href: panduanfaq,
        //   target: "_blank",
        //   download: "Panduan Pengisian Kuesioner IE PPATK 2021.pdf"
        // },
        {
            _tag: 'CSidebarNavItem',
            name: 'Ganti Password',
            icon: 'cil-user',
            to: '/gantipassword',
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Logout',
            icon: 'cil-account-logout',
            onClick: () => {
                logout()
            }
        },
        // {
        //   _tag: 'CSidebarNavTitle',
        //   _children: ['Bantuan'],
        // },
        // {
        //   _tag: 'CSidebarNavDropdown',
        //   name: 'Manual Book',
        //   icon: 'cil-browser',
        //   _children: [
        //     {
        //       _tag: 'CSidebarNavItem',
        //       name: 'Bahasa',
        //       href: panduanfaq,
        //       target: "_blank",
        //       download: "Panduan Pengisian Kuesioner IE PPATK 2021.pdf"
        //     },
        //     {
        //       _tag: 'CSidebarNavItem',
        //       name: 'English',
        //       href: panduanfaq_eng,
        //       target: "_blank",
        //       download: "Questionnaire Filling Guide.pdf"
        //     },
        //   ]
        // },
        // {
        //   _tag: 'CSidebarNavDivider',
        //   className: 'm-2'
        // }
    ]

    return (
        <>
            <CSidebar
                show={show}
                onShowChange={(val) => onChange(val)}
            >
                <CSidebarBrand className="d-md-down-none" to="/">
                    {/* <CImg
            className="c-sidebar-brand-full"
            src={'logo/Logo_PPATK.png'}
            height={35}
          />
          <CImg
            className="c-sidebar-brand-minimized"
            src={'logo/Logo_PPATK.png'}
            height={35}
          /> */}
                </CSidebarBrand>
                <CSidebarNav>

                    <CCreateElement
                        items={nav}
                        components={{
                            CSidebarNavDivider,
                            CSidebarNavDropdown,
                            CSidebarNavItem,
                            CSidebarNavTitle
                        }}
                    />
                    {/* <CSidebarNavItem className="text-secondary" to="/charts">
        <CIcon name="cil-browser" className="img-fluid"></CIcon>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Manual Book
        </CSidebarNavItem> */}
                </CSidebarNav>
                <CSidebarMinimizer className="c-d-md-down-none"/>
            </CSidebar>
        </>
    )
}

const mapStateToProps = (state) => ({
    ...state.sidebarReducer, ...state.storageReducer
});
const mapDisppatchToProps = {
    onChange: sidebarActions.onChange,
    doLogout:storageActions.doLogout
};
TheSidebar.propTypes = {
    onChange: propTypes.func,
    doLogout: propTypes.func
};

export default connect(mapStateToProps, mapDisppatchToProps)(TheSidebar);
