import axios from 'axios'
import Swal from 'sweetalert2'
import * as Survey from 'survey-react'
import secureLocalStorage from "react-secure-storage";
import {useHistory} from "react-router-dom";

let store

export const injectStore = _store => {
    store = _store
}

axios.interceptors.response.use(
    response => {
        if (!(response.data.status >= 200 && response.data.status < 300)) {
            Swal.fire({
                icon: 'error',
                title: response.data.message
            })
        } else if (response.data.status === 401 || response.data.status === 404) {
            Swal.fire({
                icon: 'error',
                title: 'Waktu login habis'
            })
            secureLocalStorage.clear()
            const history = useHistory()
            history.push('/home')
        }
        return response
    },
    error => {
        secureLocalStorage.clear()
        Swal.fire({
            icon: 'error',
            title: 'Silahkan login kembali'
        })
        const history = useHistory()
        history.push('/home')
        return Promise.reject(error)
    }
);

axios.interceptors.request.use(
    request => {

        const accessTokenResponse = store.getState().storageReducer.accessTokenResponse

        if (accessTokenResponse === "null" || !accessTokenResponse) {
            return request
        } else {
            const accessToken = accessTokenResponse["access_token"]
            request.headers = {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            return request
        }
    },
    error => {
        Swal.fire({
            icon: 'error',
            title: 'Koneksi Jaringan Terputus'
        })
        return Promise.reject(error)
    }
);

Survey.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
    const accessTokenResponse = store.getState().storageReducer.accessTokenResponse
    if (accessTokenResponse !== null) {
        const accessToken = accessTokenResponse["access_token"]
        options.request.setRequestHeader("Authorization", "Bearer " + accessToken);
    }
}
