import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


const NavbarSpi = () =>{
    return (
        <>
        <link rel="stylesheet" href="./customSPIP.css" />
                <nav className="navbar fixed-top navbar-expand-lg" style={{backgroundColor:'white'}}>
                    <div className="container-fluid">

                        
                        <div className='col-12 d-block d-sm-none'>
                                <a className="navbar-brand" href="/landingpage_spi">
                                    <div className="mx-100">
                                        <img src="https://i.ibb.co/RH9BDPS/Logo-SPI-KPK.jpg"  alt="SPI KPK" width="auto" height="75" className="d-inline-block align-center"/>
                                    </div>  
                                </a>

                                <a className="navbar-brand" href="/landingpage_spi">
                                    <div className="mx-100">
                                        <img src="https://i.ibb.co/G3cBcDj/Logo-KPK.png"  alt="Logo-KPK" width="auto" height="50" className="d-inline-block align-center"/>
                                    </div>  
                                </a>


                                <a className="navbar-brand" href="/landingpage_spi">
                                    <div className="mx-100">
                                        <img src="https://i.ibb.co/LC2jdNB/Logo-Indekstat-removebg-preview.png"  alt="Logo-Indekstat" width="auto" height="20" className="d-inline-block align-center"/>
                                    </div>  
                                </a>
                        </div>

                        <div className="col-sm content-bg d-none d-sm-block">
                                <a className="navbar-brand" href="/landingpage_spi">
                                    <div className="mx-100">
                                        <img src="https://i.ibb.co/RH9BDPS/Logo-SPI-KPK.jpg"  alt="SPI KPK" width="auto" height="95" className="d-inline-block align-center"/>
                                    </div>  
                                </a>

                                <a className="navbar-brand" href="/landingpage_spi">
                                    <div className="mx-100">
                                        <img src="https://i.ibb.co/G3cBcDj/Logo-KPK.png"  alt="Logo-KPK" width="auto" height="75" className="d-inline-block align-center"/>
                                    </div>  
                                </a>


                                <a className="navbar-brand" href="/landingpage_spi">
                                    <div className="mx-100">
                                        <img src="https://i.ibb.co/LC2jdNB/Logo-Indekstat-removebg-preview.png"  alt="Logo-Indekstat" width="auto" height="30" className="d-inline-block align-center"/>
                                    </div>  
                                </a>
			
                        </div>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        
                        <div className="collapse navbar-collapse" id="collapsibleNavbar">
                            <ul className="nav nav-pills flex-column flex-sm-row ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link active" href="/landingpage" style={{color:'black',backgroundColor:'hsla(37, 90%, 51%, 1)'}}>FAQ</a>
                                </li>
                                
                                <li className="nav-item">
                                    <a className="nav-link" href="/dashboardpage" style={{color:'black'}}>Dashboard</a>
                                </li>                      

                            </ul>
                        </div> 
                    </div>
                </nav>
        </>
    )

}

export default NavbarSpi